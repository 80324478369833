import React, { useEffect, useState } from "react";
import { Upload, Flex, Radio, notification, InputNumber, Select } from "antd";
import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import {
  addeditdata,
} from "../../controllers/meetings/meetingsController";
import { fetchIpAddress } from "../../controllers/API";


const EventsEditPage = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [error, setError] = useState([]);
  const [ADDEDITDATA, setAddEditSata] = useState(state ? state : []);

  const [passwordVisible, setPasswordVisible] = useState(false);

  // const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    vendor_account_number: ADDEDITDATA?.vendor_account_number || "",
  });
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  /*********************************************************
   *  This function is use to handle imput chnage
   *********************************************************/
  const handleChange = (e) => {
    setAddEditSata((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
    setError((pre) => ({
      ...pre,
      [e.target.name]: "",
    }));
  };


  /*********************************************************
   *  This function is use to handle form submit
   *********************************************************/
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const {
        _id,
        meet_id,
        investor_name,
        investor_id,
        company_name,
        company_id,
        time_from,
        time_to,
        table,
        priority,
        time_slot,
        date,
        status
      } = ADDEDITDATA;
      const ipAddress = await fetchIpAddress();

      const form = new FormData();
      if (_id && _id !== "undefined" && _id !== null) {
        form.append("edit_id", _id);
      }
      form.append("investor_name", investor_name);
      form.append("company_name", company_name);
      form.append("investor_id", investor_id);
      form.append("company_id", company_id);
      form.append("meet_id", meet_id);
      form.append("time_from", time_from);
      form.append("time_to", time_to);
      form.append("table", table);
      form.append("priority", priority);
      form.append("time_slot", time_slot);
      form.append("date", date);
      form.append("status", status);

      const isValidate = validateFormData(form);

      const params = {
        edit_id: _id,
        investor_name: investor_name,
        company_name: company_name,
        investor_id: investor_id,
        company_id: company_id,
        meet_id: meet_id,
        time_from: time_from,
        time_to: time_to,
        table: table,
        priority: priority,
        time_slot: time_slot,
        date: date,
        status: status 
      }

      if (isValidate) {
        const options = {
          url: "meetings/addeditdata",
          postData: params,
        };
        const res = await addeditdata(options);
        if (res.status === true) {
          notification.open({
            message: "Data updated!",
            description: `Data updated successfully.`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          navigate("/meetings/list");
        } else {
          notification.open({
            message: "Opps!",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      } else {
        notification.open({
          message: "Opps!",
          description: `Please enter the required fields.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /*********************************************************
   *  This function is used to validate form data before submit
   *********************************************************/
  const validateFormData = (formData) => {

    if (!formData.get("time_from")?.trim()) {
      setError((prevError) => ({
        ...prevError,
        time_from: "Start time is required",
      }));
      return false;
    }
    if (!formData.get("time_to")?.trim()) {
      setError((prevError) => ({
        ...prevError,
        time_to: "End time is required",
      }));
      return false;
    }
    if (!formData.get("table")?.trim()) {
      setError((prevError) => ({
        ...prevError,
        table: "Table is required",
      }));
      return false;
    }
    return true;
  };

  const formatDateToISO = (dateString) => {
    if (!dateString) return ""; // Handle empty or undefined date
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };



  return (
    <div>
      {" "}
      <div className="container-fluid">
        <div className="Inventory_edit_page_main_div">
          <div>
            <h5 className="inventory_edit_page_heading">
              {ADDEDITDATA?._id ? `Edit` : `Add`} Meeting Timing
            </h5>
          </div>
          <Link to="/meetings/list">
            <div>
              <button type="button" className="btn btn-secondary">
                <ArrowLeftOutlined /> Back
              </button>
            </div>
          </Link>
        </div>

        <div className="card shadow mb-4 dataBase_table_Add_Data">
          <div className="card-body">
            <div className="responsive">
              <form method="post" className="inventory_form_main_contnet">
                <div className="row inventory_form_main_content_form_section">
                  { ADDEDITDATA.isReschedule === 'Y' ? (
                    <div className="row">
                      <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                        <label
                          className="all_section_for_label_main_class"
                        >
                          <span style={{ color: "red" }}>*</span>Rescheduled By :{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control store_input_field"
                          value={ADDEDITDATA?.reschedule_by}
                          readOnly={true}
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                        <label
                          className="all_section_for_label_main_class"
                        >
                          <span style={{ color: "red" }}>*</span>Rescheduled Reason :{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control store_input_field"
                          value={ADDEDITDATA?.reason}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  ) : null}
                  
                  <div className="row">
                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="investor_name"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>Investor Name :{" "}
                      </label>
                      <input
                        title="Investor Name"
                        placeholder="Enter investor name"
                        type="text"
                        name="investor_name"
                        className="form-control store_input_field"
                        id="investor_name"
                        value={ADDEDITDATA?.investor_id?.investor_first_name +' '+ADDEDITDATA?.investor_id?.investor_last_name} 
                        onChange={handleChange}
                        readOnly={true}
                      />
                      {error.investor_name ? (
                        <p style={{ color: "red" }}>
                          {error.investor_name}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="company_name"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>Company Name :{" "}
                      </label>
                      <input
                        title="Company Name"
                        placeholder="Enter company name"
                        type="text"
                        name="company_name"
                        className="form-control store_input_field"
                        id="company_name"
                        value={ADDEDITDATA?.company_id?.company_name}
                        onChange={handleChange}
                        readOnly={true}
                      />
                      {error.company_name ? (
                        <p style={{ color: "red" }}>
                          {error.company_name}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-group col-lg-3 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                            htmlFor="meet_id"
                            className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                          >
                            <span style={{ color: "red" }}>*</span>Meet Id :{" "}
                          </label>
                          <input
                            title="Meet id"
                            placeholder="Enter meet id"
                            type="text"
                            name="meet_id"
                            className="form-control store_input_field"
                            id="meet_id"
                            value={ADDEDITDATA?.meet_id}
                            onChange={handleChange}
                            readOnly={true}
                          />
                          {error.meet_id ? (
                            <p style={{ color: "red" }}>
                              {error.meet_id}
                            </p>
                          ) : (
                            ""
                          )}
                    </div>

                    <div className="form-group col-lg-3 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="time_slot"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Slot :{" "}
                      </label>
                      <input
                        title="Enter Time Slot."
                        placeholder="Enter Time Slot."
                        type="text"
                        name="time_slot"
                        className="form-control store_input_field"
                        id="time_slot"
                        value={ADDEDITDATA?.time_slot}
                        onChange={handleChange}
                        readOnly={true}
                      />
                      {error.time_slot ? (
                        <p style={{ color: "red" }}>{error.time_slot}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-3 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="table"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Table no. :{" "}
                      </label>
                      <input
                        title="Enter Table no.."
                        placeholder="Enter Table no.."
                        type="text"
                        name="table"
                        className="form-control store_input_field"
                        id="table"
                        value={ADDEDITDATA?.table}
                        onChange={handleChange}
                        readOnly={true}
                      />
                      {error.table ? (
                        <p style={{ color: "red" }}>{error.table}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-3 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="date"
                        className="all_section_for_label_main_class  all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Date :
                      </label>
                      <input
                        title="Enter Date."
                        placeholder="Enter Date."
                        type="date"
                        name="date"
                        className="form-control store_input_field"
                        id="date"
                        // value={ADDEDITDATA?.date}
                        value={formatDateToISO(ADDEDITDATA?.date)}
                        onChange={handleChange}
                        readOnly={true}
                      />
                      {error.date ? (
                        <p style={{ color: "red" }}>{error.date}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-3 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="time_from"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Start time :
                      </label>
                      <input
                        title="Enter start time"
                        placeholder="Enter start time"
                        type="time"
                        name="time_from"
                        className="form-control store_input_field"
                        id="time_from"
                        value={ADDEDITDATA?.time_from}
                        onChange={handleChange}
                      />
                      {error.time_from ? (
                        <p style={{ color: "red" }}>
                          {error.time_from}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-group col-lg-3 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="time_to"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>End time :
                      </label>
                      <input
                        title="Enter end time"
                        placeholder="Enter end time"
                        type="time"
                        name="time_to"
                        className="form-control store_input_field"
                        id="time_to"
                        value={ADDEDITDATA?.time_to}
                        onChange={handleChange}
                      />
                      {error.time_to ? (
                        <p style={{ color: "red" }}>
                          {error.time_to}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* <hr /> */}
                </div>

                <div className="inventory_edit_submit_btn">
                  <button
                    type="button"
                    className="btn btn-primary inventory_save_button"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <LoadingEffect isLoading={isLoading} /> */}
    </div>
  );
};

export default EventsEditPage;
