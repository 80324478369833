import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import { notification } from "antd";
import { InfoCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const UserListDeletePopUp = ({ popUpOpen, togglePopUp }) => {
  const navigate = useNavigate();
  const handleCancel = () => {
    togglePopUp();
  };

  const handleConfirm = () => {
    try {
      navigate('/requests-sent/schedule-meeting');
    } catch (error) {
      togglePopUp();
    }
  }

  return (
    <Dialog
      open={popUpOpen}
      onClose={togglePopUp}
      maxWidth="md"
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        <div className="myMainDrawerClass">
          <div>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              
            </Typography>
          </div>
          <div>
            <IconButton onClick={togglePopUp}>
              {/* <IoMdClose /> */}
            </IconButton>
          </div>
        </div>
        <Typography
          variant="h4"
          component="div"
          className="my_delete_popup_paragraph"
          style={{textAlign:'center'}}
        >
          <p>Are you sure!</p>
        </Typography>
        <DialogActions>
          <Button onClick={handleConfirm} color="primary">
            Yes
          </Button>
          <Button onClick={togglePopUp} style={{ color: "red" }}>
            No
          </Button>
        </DialogActions>
      </div>
      {/* <div className="myDrawer">
        <React.Fragment>
          <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              Confirm
            </Typography>

            <Typography
              variant="h4"
              component="div"
              className="my_delete_popup_paragraph"
              style={{textAlign:'center'}}
            >
              Are you sure?
            </Typography>

            <React.Fragment>
              <Button className="btn btn-success" onClick={handleConfirm} color="primary">
                Yes
              </Button>
              <Button className="btn btn-danger" onClick={togglePopUp} style={{ color: "red" }}>
                No
              </Button>
            </React.Fragment>

        </React.Fragment>
      </div> */}
    </Dialog>
  );
};

export default UserListDeletePopUp;
