import React, { useEffect, useState } from "react";
import { Upload, Flex, Radio, notification, InputNumber, Select } from "antd";
import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import {
  addeditdata,
  chnageStatus,
  categoryList,
} from "../../../controllers/vendors/vendorsController";
import { fetchIpAddress } from "../../../controllers/API";

import LoadingEffect from "../../../components/Loading/LoadingEffect";
import { beforeUpload } from "../../../controllers/common";

const UserListEditPage = () => {
  const { Option } = Select;
  const { Dragger } = Upload;

  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [error, setError] = useState([]);
  const [ADDEDITDATA, setAddEditSata] = useState(state ? state : []);
  const [productCat, setProductCat] = useState([]);
  const [serviceCat, setServiceCat] = useState([]);
  const [productCatAddEditData, setProductCatAddEditData] = useState([]);
  const [serviceCatAddEditData, setServiceCatAddEditData] = useState([]);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [image, setImage] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    vendor_account_number: ADDEDITDATA?.vendor_account_number || "",
  });
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  /*********************************************************
   *  This function is use to handle imput chnage
   *********************************************************/
  const handleChange = (e) => {
    setAddEditSata((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
    setError((pre) => ({
      ...pre,
      [e.target.name]: "",
    }));
  };
  /*********************************************************
   *  This function is use to handle image
   *********************************************************/
  const handleImageChnage = (e) => {
    setImage(e.fileList);
  };
  /*********************************************************
   *  This function is use to handle genger checkbox chnage
   *********************************************************/
  const onCheckedChange = (e) => {
    setAddEditSata((pre) => ({
      ...pre,
      vendor_gender: e.target.value,
    }));
  };

  /*********************************************************
   *  This function is used to handle phone number change
   *********************************************************/
  // const handlePhoneChange = (value) => {
  //   setAddEditSata((prev) => ({
  //     ...prev,
  //     investor_phone: value,
  //   }));
  //   setError((prev) => ({
  //     ...prev,
  //     investor_phone: "", // Clear previous error message
  //   }));
  // };

  /*********************************************************
   *  This function is used to handle phone code change
   *********************************************************/
  // const handlePhoneCodeChange = (value) => {
  //   setAddEditSata((prev) => ({
  //     ...prev,
  //     vendor_country_code: value,
  //     investor_phone: "", // Clear phone number
  //   }));
  //   setError((prev) => ({
  //     ...prev,
  //     investor_phone: "", // Clear previous error message
  //   }));
  // };

  // const handleProductcategory = (value) => {
  //   console.log("value", value);
  //   setProductCatAddEditData(value);
  //   setError((preError) => ({
  //     ...preError,
  //     product_category: "",
  //   }));
  // };

  // const handleServicesCategory = (value) => {
  //   setServiceCatAddEditData(value);
  //   setError((preError) => ({
  //     ...preError,
  //     service_category: "",
  //   }));
  // };

  /*********************************************************
   *  This function is use to handle form submit
   *********************************************************/
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const {
        _id,
        investor_first_name,
        investor_last_name,
        investor_email,
        investor_phone,
        investor_designation,
        investor_firm_name,
        investor_description,
      } = ADDEDITDATA;
      const ipAddress = await fetchIpAddress();

      const form = new FormData();
      if (_id && _id !== "undefined" && _id !== null) {
        form.append("edit_id", _id);
      }
      form.append("first_name", investor_first_name?investor_first_name:'');
      form.append("last_name", investor_last_name?investor_last_name:'');
      form.append("email", investor_email?investor_email:'');
      form.append("phone", investor_phone?investor_phone:null);
      form.append("designation", investor_designation?investor_designation:'');
      form.append("firm_name", investor_firm_name ? investor_firm_name :'');
      form.append("description", investor_description ? investor_description :'');
      if (image.length > 0) {
        form.append("image", image[0]?.originFileObj);
      }

      form.append("ipAddress", ipAddress);

      const isValidate = validateFormData(form);

      if (isValidate) {
        const options = {
          url: "accounts/investors/addeditdata",
          postData: form,
        };
        const res = await addeditdata(options);
        if (res.status === true) {
          notification.open({
            message: "Data updated!",
            description: `Data updated successfully.`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          navigate("/investors/list");
        } else {
          notification.open({
            message: "Opps!",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      } else {
        notification.open({
          message: "Opps!",
          description: `Please enter the required fields.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /*********************************************************
   *  This function is used to validate form data before submit
   *********************************************************/
  const validateFormData = (formData) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const numberPattern = /^[6-9][0-9]{9}$/;
    const name = /^[A-Za-z\s]+$/; 
    const companyname = /^[A-Za-z0-9\s]+$/;
    // const vendor_country_code = formData.get("country_code");

    if (!formData.get("first_name")?.trim() || formData.get("first_name") === '' || formData.get("first_name") === 'undefined') {
      setError((prevError) => ({
        ...prevError,
        investor_first_name: "First name is required",
      }));
      return false;
    }
    if(!name.test(formData.get("first_name"))){
      setError((prevError) => ({
        ...prevError,
        investor_first_name: "Please enter a valid name",
      }));
      return false;
    }
    const myLastname = formData.get("last_name");
    if(myLastname){
      if(!name.test(myLastname)){
        setError((prevError) => ({
          ...prevError,
          investor_last_name: "Please enter a valid name",
        }));
        return false;
      }
    }
    if (!formData.get("email")?.trim() || formData.get("email") === '' || formData.get("email") === 'undefined') {
      setError((prevError) => ({
        ...prevError,
        investor_email: "Email address is required",
      }));
      return false;
    }
    if (!emailPattern.test(formData.get("email"))) {
      setError((prevError) => ({
        ...prevError,
        investor_email: "Please enter a valid email address",
      }));
      return false;
    }

    // const myPhone = formData.get("phone");
    // if(myPhone != '' || myPhone != null || myPhone != 'undefined'){
    //   if(!numberPattern.test(myPhone)){
    //     setError((prevError) => ({
    //       ...prevError,
    //       investor_phone: "Invalid phone",
    //     }));
    //     return false;
    //   }
    // }

    // const myFirmname = formData.get("firm_name");
    // if(myFirmname){
    //   if(!companyname.test(formData.get("firm_name"))){
    //     setError((prevError) => ({
    //       ...prevError,
    //       investor_firm_name: "Please enter a valid firm name",
    //     }));
    //     return false;
    //   }
    // }

    // const mydesignation = formData.get("designation");
    // if(mydesignation){
    //   if(!name.test(mydesignation)){
    //     setError((prevError) => ({
    //       ...prevError,
    //       investor_designation: "Please enter a valid designation",
    //     }));
    //     return false;
    //   }
    // }
    
    // if (!ADDEDITDATA?._id) {
    //   if (!formData.get("image")) {
    //     setError((prevError) => ({
    //       ...prevError,
    //       image: "Image is required",
    //     }));
    //     return false;
    //   }
    // }
    return true;
  };

  /*********************************************************
   *  This function is use to handle delete account
   *********************************************************/
  const handleDelete = async () => {
    try {
      const options = {
        url: `accounts/investors/changestatus`,
        postData: {
          user_id: ADDEDITDATA?._id,
          status: "D",
        },
      };
      const res = await chnageStatus(options);
      if (res.status === true) {
        notification.open({
          message: "User deleted successfully.",
          description: `Data delete successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
        navigate("/users/list");
      } else {
        notification.open({
          message: "Opps!",
          description: `${res?.message}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      notification.open({
        message: "Opps!",
        description: `Operation not perform yet! please try in some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
  };


  return (
    <div>
      {" "}
      <div className="container-fluid">
        <div className="Inventory_edit_page_main_div">
          <div>
            <h5 className="inventory_edit_page_heading">
              {ADDEDITDATA?._id ? `Edit` : `Add`} Investors
            </h5>
          </div>
          <Link to="/investors/list">
            <div>
              <button type="button" className="btn btn-secondary">
                <ArrowLeftOutlined /> Back
              </button>
            </div>
          </Link>
        </div>

        <div className="card shadow mb-4 dataBase_table_Add_Data">
          <div className="card-body">
            <div className="responsive">
              <form method="post" className="inventory_form_main_contnet">
                <div className="row inventory_form_main_content_form_section">
                  <div className="row">
                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="vendor_first_name"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>First Name :{" "}
                      </label>
                      <input
                        title="First Name"
                        placeholder="Enter first name"
                        type="text"
                        name="investor_first_name"
                        className="form-control store_input_field"
                        id="investor_first_name"
                        value={ADDEDITDATA?.investor_first_name}
                        onChange={handleChange}
                      />
                      {error.investor_first_name ? (
                        <p style={{ color: "red" }}>
                          {error.investor_first_name}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="investor_last_name"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>Last Name :{" "}
                      </label>
                      <input
                        title="Enter last name."
                        placeholder="Enter last name."
                        type="text"
                        name="investor_last_name"
                        className="form-control store_input_field"
                        id="investor_last_name"
                        value={ADDEDITDATA?.investor_last_name}
                        onChange={handleChange}
                      />
                      {error.investor_last_name ? (
                        <p style={{ color: "red" }}>{error.investor_last_name}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="investor_email"
                        className="all_section_for_label_main_class  all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Email Address :
                      </label>
                      <input
                        title="Enter email address."
                        placeholder="Enter email address."
                        type="text"
                        name="investor_email"
                        className="form-control store_input_field"
                        id="investor_email"
                        value={ADDEDITDATA?.investor_email}
                        onChange={handleChange}
                      />
                      {error.investor_email ? (
                        <p style={{ color: "red" }}>{error.investor_email}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="investor_phone"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Phone :
                      </label>
                      <input
                        title="Enter Phone no."
                        placeholder="Enter Phone no."
                        type="number"
                        name="investor_phone"
                        className="form-control store_input_field"
                        id="investor_phone"
                        value={ADDEDITDATA?.investor_phone}
                        onChange={handleChange}
                      />
                      {error.investor_phone ? (
                        <p style={{ color: "red" }}>
                          {error.investor_phone}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* <hr /> */}
                  <div className="row">
                  <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="investor_firm_name"
                        className="all_section_for_label_main_class  all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Investor Firm Name :
                      </label>
                      <input
                        title="Enter firm name"
                        placeholder="Enter firm name"
                        type="text"
                        name="investor_firm_name"
                        className="form-control store_input_field"
                        id="investor_firm_name"
                        value={ADDEDITDATA?.investor_firm_name}
                        onChange={handleChange}
                      />
                      {error.investor_firm_name ? (
                        <p style={{ color: "red" }}>{error.investor_firm_name}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="investor_designation"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Designation :
                      </label>
                      <input
                        title="Enter designation"
                        placeholder="Enter designation"
                        type="text"
                        name="investor_designation"
                        className="form-control store_input_field"
                        id="investor_designation"
                        value={ADDEDITDATA?.investor_designation}
                        onChange={handleChange}
                      />
                      {error.investor_designation ? (
                        <p style={{ color: "red" }}>
                          {error.investor_designation}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data">
                    <label
                      htmlFor="investor_description"
                      className="all_section_for_label_main_class"
                    >
                      Description :
                    </label>
                    <textarea
                      title="Enter description"
                      rows="5"
                      name="investor_description"
                      className="form-control store_input_field"
                      id="investor_description"
                      value={ADDEDITDATA?.investor_description}
                      onChange={handleChange}
                    />
                    {error.investor_description ? (
                      <p style={{ color: "red" }}>{error.investor_description}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="all_section_for_label_main_class"
                  >
                    {!ADDEDITDATA?._id && (
                      <span style={{ color: "red" }}>*</span>
                    )}
                    Profile Image
                  </label>
                  <Dragger
                    className="about_us_dragger_file"
                    maxCount="1"
                    // listType="picture"
                    onChange={handleImageChnage}
                    beforeUpload={beforeUpload}
                  >
                    <button
                      type="button"
                      className="about_us_dragger_file_button"
                    >
                      Upload file
                    </button>
                  </Dragger>
                  <p
                    style={{ color: "blue" }}
                    className="all_top_and_bottom_for_input_text"
                  >
                    *Note: Please select an image format (JPEG/JPG/PNG).
                  </p>
                  {error.image ? (
                    <p style={{ color: "red" }}>{error.image}</p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="inventory_edit_submit_btn">
                  <button
                    type="button"
                    className="btn btn-primary inventory_save_button"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <LoadingEffect isLoading={isLoading} /> */}
    </div>
  );
};

export default UserListEditPage;
