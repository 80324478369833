import {API_ADMIN_URL} from '../../config/constants'
import { postRequest } from '../API'

/*********************************************************
* Function Name : List
* Description   : Get list of all service category
* By            : Afsar Ali
* Date          : 29-01-2024 
*********************************************************/
export const list = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}requests/interest-sent-list`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function


/*********************************************************
* Function Name : chnageStatus
* Description   : chnage service status
* By            : Afsar Ali
* Date          : 04-03-2024 
*********************************************************/
export const changePiority = async (options) =>{
    try {
        const { url, postData={} } = options;
        const params = {
            url : `${API_ADMIN_URL}${url}`,
            postData : {
                ...postData,
                ipAddress : sessionStorage.getItem('IP_ADDRESS')
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function


/*********************************************************
* Function Name : chnageStatus
* Description   : chnage service status
* By            : Afsar Ali
* Date          : 04-03-2024 
*********************************************************/
export const chnageStatus = async (options) =>{
    try {
        const { url, postData={} } = options;
        const params = {
            url : `${API_ADMIN_URL}${url}`,
            postData : {
                ...postData,
                ipAddress : sessionStorage.getItem('IP_ADDRESS')
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function 

/*********************************************************
* Function Name : createBulk
* Description   : Create bulk data
* By            : Afsar Ali
* Date          : 22-08-2024 
*********************************************************/
export const createBulk = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}meetings/bulkCreate`,
            postData :options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function 


/*********************************************************
* Function Name : createBulk
* Description   : Create bulk data
* By            : Afsar Ali
* Date          : 22-08-2024 
*********************************************************/
export const clearAll = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}meetings/clear-all`,
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function 


/*********************************************************
* Function Name : insertBulkInterest
* Description   : This function is used for insert bulk interest
* By            : Afsar Ali
* Date          : 10-09-2024 
*********************************************************/
export const insertBulkInterest = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}requests/bulk-create`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.response.result}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function