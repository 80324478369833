import axios from "axios";
/*****************************************************
 * This function is use to generate schedular transaction id
 *****************************************************/
export const createTransaction = async ()=>{
    try{
        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://chiratae.algoapi.in/apiv2/create',
        headers: { 
            'Authorization': 'KztpEM93Xq25ubxhFLHdsT4raVYJPBckygAn68U7'
            // 'Cookie': 'ci_session=crgszhxlflq3yru4izoimiha'
        }
        };
        return await axios.request(config);
    } catch(error){
        return error;
    }
} //End of the function

/*****************************************************
 * This function is use to push data to schedular
 *****************************************************/
export const pushData = async (options)=>{
    try{
        const headers = { 'Authorization' : 'KztpEM93Xq25ubxhFLHdsT4raVYJPBckygAn68U7' };
        return await axios.post(`https://chiratae.algoapi.in/apiv2/pushdata/${options.Transaction}`, options, { headers });
    } catch(error){
        return error;
    }
} //End of the function

/*****************************************************
 * This function is use to ftch data to schedular
 *****************************************************/
export const fetchData = async (id)=>{
    try{
        const headers = { 'Authorization' : 'KztpEM93Xq25ubxhFLHdsT4raVYJPBckygAn68U7' };
        return await axios.post(`https://chiratae.algoapi.in/apiv2/fetch/${id}`, {}, { headers });
    } catch(error){
        return error;
    }
} //End of the function