import React, { useEffect, useState } from "react";

import { FaDesktop, FaArrowLeft } from "react-icons/fa";

import "react-quill/dist/quill.snow.css";
import ExcelJS from 'exceljs';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { bulkUpload } from "../../../controllers/company/companyController";
import { notification, Progress, Upload, Modal, Button } from "antd";
import { CheckCircleTwoTone, CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";

import { beforeUpload, createSlug } from '../../../controllers/common';



const conicColors = {
  '0%': '#87d068',
  '50%': '#ffe58f',
  '100%': '#ffccc7',
};

function ProductListEditPage() {
  const navigate = useNavigate();

  const { Dragger } = Upload;
  const [error, setError] = useState([]);

  const [fileName, setFileName] = useState('');
  const [jsonData, setJsonData] = useState(null);

  const [progress, setProgress] = useState(0);

  /*********************************************************
   *  This function is use to handle success notification show
   *********************************************************/
  const handleSuccessMsg = () => {
    notification.open({
      message: "Success!",
      description: " Data Saved Successfully .",
      placement: "topRight",
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
    setTimeout(() => {
      notification.destroy();
    }, 2000);
  }; //End
  /*********************************************************
   *  This function is use to handle error notification show
   *********************************************************/
  const handleErrorMsg = () => {
    notification.open({
      message: "Opps!",
      description: error?.formError,
      placement: "topRight",
      icon: <CloseOutlined twoToneColor="#fc2339" />,
    });
    setTimeout(() => {
      notification.destroy();
    }, 2000);
  }; //End


  //////////////Modal/////////
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bulkErrorMsg, setBulkerrorMsg] = useState("");
  const showModal = (msg) => {
    setBulkerrorMsg(msg);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  ///////Modal/////////////////

  /*********************************************************
   *  This function is use to handle image object
   *********************************************************/
  const handleFileChange = (e) => {
    // setImageList(e.fileList[0].originFileObj);
    setFileName(e.fileList[0]?.originFileObj?.name);
    const file = e.fileList[0].originFileObj;
    const workbook = new ExcelJS.Workbook();
    const reader = new FileReader();

    reader.onload = async (event) => {
      const buffer = event.target.result;
      await workbook.xlsx.load(buffer);

      const worksheet = workbook.worksheets[0];
      const rows = [];

      worksheet.eachRow((row, rowNumber) => {
        const rowValues = row.values.slice(1); // Remove the first empty value
        rows.push(rowValues);
      });

      // Convert rows to JSON (assuming first row is the header)
      const headers = rows[0];
      const data = rows.slice(1).map(row => {
        const rowData = {};
        row.forEach((cell, index) => {
          rowData[headers[index]] = cell;
        });
        return rowData;
      });
      setJsonData(data);
    };
    reader.readAsArrayBuffer(file);
    setError((pre)=>({
      ...pre,
      file : ""
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
          const option = {
              data: jsonData
          };
          console.log('myJson data',option);
          if (jsonData?.length > 0) {
              try {
                  const dataArray = await transformData(option);
                  let bulkError = false;
                  if (dataArray && dataArray?.length > 0) {
                      for (let i = 0; i < dataArray.length; i += 10) {
                          const batch = dataArray.slice(i, i + 10);
                          const res = await bulkUpload(batch);
                          if (res.status !== true) {
                              bulkError = true;
                                showModal(res?.message);
                                setProgress(0);
                                navigate("/company/bulk-upload");
                                break;
                              // setError((pre) => ({
                              //     ...pre,
                              //     formError: res?.message,
                              // }));
                              // break;
                          }
                          const progressPercentage = Math.round(((i + 10) / dataArray.length) * 100);
                          setProgress(progressPercentage);
                      }
                      // handleSuccessMsg();
                      // navigate("/company/list");
                      if(bulkError === false){
                        handleSuccessMsg();
                        navigate("/company/list");
                      }
                  }
              } catch (error) {
                  setError((pre) => ({
                      ...pre,
                      formError: error.message,
                  }));
              }
          } else {
              setError((pre) => ({
                  ...pre,
                  file: "Please upload file"
              }));
          }
    //   } 
    } catch (error) {
      console.log('error',error)
    }
};

  const transformData = async (option) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = option;
            let result = [];

            for (let i = 0; i < data.length; i += 10) {
                const batch = data.slice(i, i + 10);
                const transformedBatch = await Promise.all(batch.map(async (item) => {
                    return {
                        serial_no: item?.serial_no,
                        company_name: item?.company_name,
                        company_founder_name: item?.company_founder_name,
                        company_ceo_name: item?.company_ceo_name,
                        company_stage: item?.company_stage,
                        company_email: item?.company_email,
                        company_phone: item?.company_phone,
                        company_sector: item?.company_sector,
                        company_description: item?.company_description,
                        company_image: item?.company_image,
                        status: 'A'
                    };
                }));
                result = result.concat(transformedBatch);
            }
            resolve(result);
        } catch (error) {
            reject(error);
        }
    });
};


  return (
    <div>
      {" "}
      <div className="container-fluid">
        <div className="Inventory_edit_page_main_div">
          <div>
            <h5 className="inventory_edit_page_heading">
              Import Companies{" "}
            </h5>
          </div>
          <Link to={`/company/list`}>
            <div>
              <button type="button" className="btn btn-secondary">
                <FaArrowLeft /> Back
              </button>
            </div>
          </Link>
        </div>

        <div className="card shadow mb-4 dataBase_table_Add_Data mt-10">
          <div className="card-body">
            <div className="responsive">
              <form action="" >
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1" className="all_vendor_radio_button_div">File (<Link to={`/companyTemplate/PortfolioCompanyUploadTemplate.xlsx`} target="_blank">Download Template)</Link></label>
                  <Dragger
                    className="about_us_dragger_file"
                    onChange={handleFileChange}
                    accept='.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    showUploadList={false}
                  >
                    <button type="button" className="about_us_dragger_file_button" > {fileName?fileName:`Upload file`}</button> 
                    <p style={{color:"blue"}}>*Note: Please choose a file of xlsx format only.</p>
                    {error?.file ? ( <p style={{ color: "red" }}>{error.file}</p> ) : ("")}
                  </Dragger>
                </div>
                {progress === 0 ? (
                  <div className="inventory_edit_submit_btn">
                    <button type="button" className="btn btn-primary inventory_save_button all_save_btn_btn_btn" onClick={handleSubmit} > Save </button>
                  </div>
                ):(
                  <div class="form-group">
                    <div class="form-group">
                      <Progress type="line" percent={progress} strokeColor={conicColors} />
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal open={isModalOpen} onCancel={handleCancel} 
        footer={[
                <Button key="close" type="primary" onClick={handleCancel}>
                  Close
                </Button>,
               ]}>
        <InfoCircleOutlined style={{color: "red",fontSize:"45px"}} />
        <h4 style={{color:"red"}}>Oops!</h4>
        <h6>{bulkErrorMsg}</h6>
      </Modal>
    </div>
  );
}

export default ProductListEditPage;
