import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, notification, Drawer, Button, Space } from "antd";
import { FaEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import TobNavBar from "../DashBaord/Top_navbar";
import { Link, useNavigate } from "react-router-dom";
import { Flex, Spin } from "antd";
import moment from "moment";


import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  LikeOutlined,
  DislikeOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined
} from "@ant-design/icons";

import { ASSETS_BASE_URL } from "../../config/constants";
import SkeltonList from "../../components/SkeltonEffect/list";
import {
    list,
  chnageStatus,changePiority
} from "../../controllers/interests/requestsController";
import {slotslist, avgSetlist, list as eventsList} from "../../../src/controllers/events/eventsController";
import { getPage, statusInterestMessage } from "../../controllers/common";

import { FaPlusSquare } from "react-icons/fa";

import { createTransaction, pushData, fetchData } from '../../controllers/interests/SchedularController';
import { createBulk, clearAll} from '../../controllers/interests/requestsController';

function VendorList(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

VendorList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ScheduleMeetings() {

  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () => {
    setFilterShow(!isFilterShow);
  } 

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  /************************************************************************************* */
  const [isLoading, setIsLoading] =useState(true);
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [deleteId, setDeleteId] = useState("");
  const [ALINTERESTDATA, setInterestData] = useState([]);
  const [ALLMITTINGSDATA, setMeetingsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [ALLSLOTS, setSLotsData] = useState([]);
  const [ALLAVGSETTING, setAvgSetting] = useState([]);
  const [EVENTSDATA, setEventsData] = useState([]);
  const [TOTALTABLES, setTotalTables] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: "",
    to: new Date(),
    filter_by: "",
    search: "",
  }); //End

  const [showRequest, setShowRequest] = useState("");

  const [transaction, setTransaction] = useState('');
  const generateTransaction = async () =>{
    try {
      const res = await createTransaction();
      setTransaction(res?.data?.Transaction || '');
    } catch (error) {
      
    }
  }

  const getTableCount = async (EVENTSDATA) => {
    return EVENTSDATA[0]?.event_total_tables;
  }

  const pushMeetingdata = async () => {
    const formatedList = await generatePushData(ALINTERESTDATA);
    const formatedSlotList = await generateSlotData(ALLSLOTS);
    const formatedSettingList = await generateSettingData(ALLAVGSETTING);
    const tableCount = await getTableCount(EVENTSDATA);

    if(formatedList.length > 0){
      const options = {
        "Transaction" : transaction,
        "Participant List" : formatedList,
        "Time Slots": formatedSlotList,
        "Tables": String(tableCount),
        "Avg Settings": formatedSettingList
      }
      const res = await pushData(options);
      if(res.request.status === 200){
        setTimeout(checkDataStatus, 10000);
        // checkDataStatus();
      }
    }
  }

  const checkDataStatus = async () => {
    try {
      const fetchedData = await fetchData(transaction);
      if(fetchedData.data.Status === 'Success'){
        setMeetingsData(fetchedData['data']['Compiled List'])
        setIsLoading(false);
        setTransaction('');
      }
    } catch (fetchError) {
      console.error('Error fetching data:', fetchError);
      // Optionally handle fetch errors here or retry
      navigate('requests-sent/list');
    }
  };

  const generatePushData = async (ALINTERESTDATA) => {
    return ALINTERESTDATA.map((items) => ({
      "Investor ID": items.investor_data._id,
      "Investor Name": items.investor_data.investor_firm_name,
      "Portfolio Company ID": items.company_data._id,
      "Portfolio Company Name": items.company_data.company_name,
      "Priority": String(items.investor_priority)
    }));
  }
  
  // const generateSlotData = async (ALLSLOTS) => {
  //   return ALLSLOTS.map((items) => ({
  //     "Slot": items.slot_name,
  //     "Date": items.slot_date,
  //     "From time": items.slot_start_time,
  //     "To time": items.slot_end_time
  //   }));
  // }
  const generateSlotData = async (ALLSLOTS) => {
    return ALLSLOTS.map((items) => {
      const formattedDate = moment(items.slot_date, 'YYYY-MM-DD').format('DD-MM-YYYY');
      return {
        "Slot": items.slot_name,
        "Date": formattedDate,
        "From Time": items.slot_start_time,
        "To Time": items.slot_end_time
      };
    });
  };

  const generateSettingData = async (ALLAVGSETTING) => {
    return ALLAVGSETTING.map((items) => ({
      "Meeting Count From": String(items.avg_meet_count_from),
      "Meeting Count To": String(items.avg_meet_count_to),
      "Duration in Minutes": String(items.meet_duration)
    }));
  }
  

  useEffect(()=>{
    if(transaction && ALINTERESTDATA?.length> 0 && ALLSLOTS?.length > 0 && ALLAVGSETTING?.length > 0 && EVENTSDATA.length > 0){
      pushMeetingdata();
    }
  },[transaction, ALINTERESTDATA, ALLSLOTS, ALLAVGSETTING, EVENTSDATA])


  /*********************************************************
   *  This function is use to fetch investors list
   *********************************************************/
  const getEventList = async () => {
    try {
      setIsLoading(true);
      setEventsData([]);
      const options = {
        type: "",
        condition: { status: 'A' },
      };
      const listData = await eventsList(options);
      if (listData.status === true) {
        if (listData.result.length > 0) {
          setEventsData(listData?.result);
        } else {
          setEventsData([]);
        }
      } else {
        setEventsData([]);
      }
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
    }
  }; //End

  /*********************************************************
   *  This function is use to fetch investors list
   *********************************************************/
  const getList = async () => {
    try {
      setIsLoading(true);
      setListData([]);
      const options = {
        type: "",
        condition: { status: 'A' },
      };
      const listData = await list(options);
      if (listData.status === true) {
        if (listData.result.length > 0) {
          setInterestData(listData?.result);
        } else {
          setListData([]);
        }
      } else {
        setListData([]);
      }
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
    }
  }; //End

  /*********************************************************
   *  This function is use to slots list
   *********************************************************/
  const getSlotsList = async () => {
    try {
      setIsLoading(true);
      setSLotsData([]);
      const options = {
        type: "",
        condition: {},
      };
      const listData = await slotslist(options);
      if (listData.status === true) {
        if (listData.result.length > 0) {
          setSLotsData(listData?.result);
        } else {
          setSLotsData([]);
        }
      } else {
        setSLotsData([]);
      }
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
    }
  }; //End

  /*********************************************************
   *  This function is use to slots list
   *********************************************************/
  const getAvgSettList = async () => {
    try {
      setIsLoading(true);
      setAvgSetting([]);
      const options = {
        type: "",
        condition: {},
      };
      const listData = await avgSetlist(options);
      console.log('sett list',listData);
      if (listData.status === true) {
        if (listData.result.length > 0) {
          setAvgSetting(listData?.result);
        } else {
          setAvgSetting([]);
        }
      } else {
        setAvgSetting([]);
      }
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
    }
  }; //End

  /*********************************************************
   *  This function will load when page load and with dependency update
   *********************************************************/
  useEffect(() => {
    getList();
    getEventList();
    getSlotsList();
    getAvgSettList();
    generateTransaction();
    targetRef.current.scrollIntoView({
      behavior:'smooth',
    });
    document.title = "Chiratae Super Admin || Events List";
  }, [popUpOpen, filter]);

  const [progress, setProgress] = useState(0);

  const handlePublish = async () => {
    try {

      const clearres = await clearAll();  // first clear existing meetings data 

      if(clearres.status === true){
        const finalParams = await transformData(ALLMITTINGSDATA);
        for (let i = 0; i < finalParams.length; i += 10) {
          const batch = finalParams.slice(i, i + 10);
          const res = await createBulk(batch);
          if (res.status !== true) {
            notification.open({
              message: "Oops!!",
              description: `Error while generate.`,
              placement: "topRight",
              icon: <CheckCircleOutlined twoToneColor="red" />,
            });
            setProgress(0);
            break;
          }
          const progressPercentage = Math.round(((i + 10) / finalParams.length) * 100);
          setProgress(progressPercentage);
        }
        notification.open({
          message: "Success",
          description: "Meetings generated successfully.",
          placement: "topRight",
          icon: <InfoCircleOutlined twoToneColor="green" />,
        });
        setProgress(0);
        navigate('/requests-sent/list');
      } else {
        notification.open({
          message: "Opps!",
          description: "Cannot clear meetings first!",
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
        });
      }
    } catch (error) {
      console.error('Error in handlePublish:', error);
    }
  };

  // const transformData = (data) => {
  //   try {
  //     const date = items['Date'];
  //     const time_from = items['Time To'];
  //     const [day, month, year] = date.split("-");
  //     const [hours, minutes] = time_from.split(":");
  //     const isoDate = new Date(`${year}-${month}-${day}T${hours}:${minutes}:00Z`);
  //     const isoString = isoDate.toISOString();
  //     return data.map((items) => ({
  //       "meet_id": items['Meet ID'],
  //       "time_slot": items['Time Slot'],
  //       "table": items['Table'],
  //       "date": items['Date'],
  //       "time_from": items['Time From'],
  //       "time_to": items['Time To'],
  //       "isoMeetingDate" :isoString,
  //       "investor_id": items['Investor ID'],
  //       "investor_name": items['Investor Name'],
  //       "company_id": items['Portfolio Company ID'],
  //       "company_name": items['Portfolio Company Name'],
  //       "priority": items['Priority'],
  //       "status": items['Status']
  //     }));
  //   } catch (error) {
      
  //   }
  // }

  const transformData = (data) => {
    try {
      return data.map((items) => {
        const date = items['Date'];
        const time_from = items['Time From'];
  
        const [day, month, year] = date.split("-");
        const [hours, minutes] = time_from.split(":");
  
        const isoDate = new Date(`${year}-${month}-${day}T${hours}:${minutes}:00Z`);
        const isoString = isoDate.toISOString();
  
        return {
          "meet_id": items['Meet ID'],
          "time_slot": items['Time Slot'],
          "table": items['Table'],
          "date": items['Date'],
          "time_from": items['Time From'],
          "time_to": items['Time To'],
          "isoMeetingDate": isoString,
          "investor_id": items['Investor ID'],
          "investor_name": items['Investor Name'],
          "company_id": items['Portfolio Company ID'],
          "company_name": items['Portfolio Company Name'],
          "priority": items['Priority'],
          "status": items['Status']
        };
      });
    } catch (error) {
      console.error("An error occurred while transforming data:", error);
    }
  };
  

  return (
    <React.Fragment>
      <div className="container-fluid" ref={targetRef}>
        <TobNavBar title={"Schedule Meetings"}></TobNavBar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <p>Total number of request : {ALINTERESTDATA?.length || 0}</p>
                </div>

                <div className="all_tabs_value">
                  <p>Total number of meetings : {ALLMITTINGSDATA?.length || `Preparing`}</p>
                </div>
                
                <div className="fa_filter_rating_review filter_img_and_add_btn_main">
                  <div>
                    <Link to={'https://chiratae.algoapi.in/apiv2/exceldownload/latest'} target="_blank" >
                    <button type="button" class="btn btn-primary">
                      <span className="request_new_categories">View Meeting</span>
                    </button>
                    </Link>
                  </div>
                  <div onClick={() => handlePublish()}>
                    <button type="button" class="btn btn-dark">
                      <span className="request_new_categories">Publish</span>
                    </button>
                  </div>
                </div>
              </div>
            </Box>
            
            <VendorList>
              <React.Fragment>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              {/* <th>Sl. No</th> */}
                              <th>Meet ID</th>
                              <th>Time Slot</th>
                              {/* <th>Meet ID</th> */}
                              <th>Table</th>
                              <th>Date</th>
                              <th>Time From</th>
                              <th>Time To</th>
                              <th>Investor</th>
                              <th>Company</th>
                              <th>Priority</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLMITTINGSDATA?.length > 0 ? (
                              ALLMITTINGSDATA.map((items, index) => (
                                <>
                                  <tr key={index}>
                                    <td className="admin_user_list_date">{items['Meet ID']}</td>
                                    <td className="admin_user_list_date"> {items['Time Slot']} </td>
                                    {/* <td className="admin_user_list_date">{items['Meet ID']}</td> */}
                                    <td className="admin_user_list_date">{items['Table']}</td>
                                    <td className="admin_user_list_date">{items['Date']}</td>
                                    <td className="admin_user_list_date">{items['Time From']}</td>
                                    <td className="admin_user_list_date">{items['Time To']}</td>
                                    <td className="admin_user_list_date">{items['Investor Name']}</td>
                                    <td className="admin_user_list_date">{items['Portfolio Company Name']}</td>
                                    <td className="admin_user_list_date">{items['Priority']}</td>
                                    <td className="admin_user_list_date">{items['Status']}</td>
                                  </tr>
                                </>
                              ))
                            ) : isLoading ? <SkeltonList row={10} col={10} /> : <tr> 
                                <td colspan="5" className="img-center" ><p>No data to show</p></td> 
                            </tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div> 
              </React.Fragment>
            </VendorList>
          </Box>
        </div>
        <Flex align="center" gap="middle">
          <Spin 
              size="large" 
              spinning={isLoading}
              fullscreen={true}
              tip="Kindly hold on while we prepare your data."
          >  </Spin>
        </Flex>

        <Flex align="center" gap="middle">
          <Spin 
              size="large" 
              spinning={(progress > 0)?true:false}
              fullscreen={true}
              tip={`${progress} % completed`}
          >  </Spin>
        </Flex>
      </div>
    </React.Fragment>
  );
}
