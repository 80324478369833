import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, notification, Drawer, Button, Space } from "antd";
import { FaEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import TobNavBar from "../DashBaord/Top_navbar";
import { Link, useNavigate } from "react-router-dom";


import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  LikeOutlined,
  DislikeOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined
} from "@ant-design/icons";

import moment from "moment";
import { ASSETS_BASE_URL } from "../../config/constants";
import SkeltonList from "../../components/SkeltonEffect/list";
import {list , chnageStatus} from "../../controllers/meetings/meetingsController";
import { getPage, statusInterestMessage } from "../../controllers/common";

import { FaPlusSquare } from "react-icons/fa";

function VendorList(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// VendorList.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {

  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () => {
    setFilterShow(!isFilterShow);
  } 

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  /************************************************************************************* */
  const [isLoading, setIsLoading] =useState(true);
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [deleteId, setDeleteId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: "",
    to: new Date(),
    filter_by: "",
    search: "",
  }); //End

  const [showInvFeedBck, setShowInvFeedBck] = useState("Y");
  const [showCompFeedBck, setShowCompFeedBck] = useState("");


  /*********************************************************
   *  This function is use to fetch investors list
   *********************************************************/
  const getList = async () => {
    try {
      setExpandedFeedback({});///////
      setIsLoading(true);
      setListData([]);
      const options = {
        type: "",
        condition: {
          ...(showInvFeedBck ? { isInvestorFeedback: showInvFeedBck } : ""),
          ...(showCompFeedBck ? { isCompanyFeedback: showCompFeedBck } : "")
        },
        ...(filter?{filter:filter}:null),
        select: {},
        sort: { status: 1, _id: -1 },
        skip: SKIP ? SKIP : 0,
        limit: LIMIT ? LIMIT : 10,
      };
      const listData = await list(options);
      if (listData.status === true) {
        if (listData.result.length > 0) {
          setListData(listData?.result);
        } else {
          setListData([]);
        }
        const countOptions = {
          type: "count",
          condition: {
            ...(showInvFeedBck ? { isInvestorFeedback: showInvFeedBck } : ""),
            ...(showCompFeedBck ? { isCompanyFeedback: showCompFeedBck } : "")
          },
          ...(filter?{filter:filter}:null)
        };
        const count = await list(countOptions);
        setTotalPages(getPage(count?.result));
      } else {
        setListData([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }; //End


  /*********************************************************
   *  This function is for handle page change
   *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage - 1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };


  /*********************************************************
   *  This function is ued for handle filter input chnage
   *********************************************************/
  const handleFilterReset = () => {
    try {
      setFilter('');
      inputRef1.current.value = '';
      inputRef2.current.value = '';
      inputRef3.current.value = '';
      inputRef4.current.value = '';
    } catch (error) {
      
    }
  }

  const handleFilterApply = (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      setFilter((pre)=>({
        ...pre,
        'filter_by' : form.get('filter_by') || '',
        'search'    : form.get('search') || '',
        'to'        : form.get('to_date') || '',
        'from'      : form.get('from_date') || '',

      }))
      // console.log('filter',filter);
      // alert('submit');
      handleFiterDrawer();
      setSkip(0);
    } catch (error) {
      
    }
  }


  ////////////////////////////
  const [expandedFeedback, setExpandedFeedback] = useState({});

  const toggleFeedback = (id, type) => {
    setExpandedFeedback((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [type]: !prevState[id]?.[type],
      },
    }));
  };
  ////////////////////////////

  /*********************************************************
   *  This function will load when page load and with dependency update
   *********************************************************/
  useEffect(() => {
    getList();
    targetRef.current.scrollIntoView({
      behavior:'smooth',
    });
    document.title = "Chiratae Super Admin || Feedbacks List";
  }, [currentPage, showInvFeedBck, showCompFeedBck,popUpOpen, filter]);


  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <TobNavBar title={"Feedbacks List"}></TobNavBar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="Investor's Feedback"
                      className="tabs_main_contnet"
                      {...a11yProps(1)}
                      onClick={() => {
                        setShowInvFeedBck("Y");
                        setShowCompFeedBck("");
                    }}
                    />
                    <Tab
                      label="Company's Feedback"
                      className="tabs_main_contnet"
                      {...a11yProps(2)}
                      onClick={() => {
                        setShowInvFeedBck("");
                        setShowCompFeedBck("Y");
                    }}
                    />
                  </Tabs>
                </div>
              </div>
            </Box>
            <VendorList>
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th style={{width:"8%"}}>Sr no.</th>
                              <th style={{width:"10%"}}>Meet Id</th>
                              <th>Name</th>
                              <th>Feedback</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  {/* <tr key={index}>
                                    <td className="admin_user_list_date"> {items?.meet_id} </td>
                                    <td className="admin_user_list_date">
                                      {showInvFeedBck === "Y" && items?.investor_name}
                                      {showCompFeedBck === "Y" && items?.company_name}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {showInvFeedBck === "Y" && items?.investorFeedback }
                                      {showCompFeedBck === "Y" && items?.companyFeedback}
                                    </td>
                                  </tr> */}
                                  <tr>
                                    <td>{(currentPage - 1) * 10 + index + 1}</td>
                                    <td className="admin_user_list_date">{items?.meet_id}</td>
                                    <td className="admin_user_list_date">
                                      {showInvFeedBck === "Y" && items?.investor_id?.investor_first_name +" "+items?.investor_id?.investor_last_name}
                                      {showCompFeedBck === "Y" && items?.company_id?.company_name}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {showInvFeedBck === "Y" && (
                                        <>
                                          {expandedFeedback[items._id]?.investor
                                            ? items.investorFeedback
                                            : items?.investorFeedback?.substring(0, 100)}{' '}
                                          {items?.investorFeedback?.length > 100 && (
                                            <span
                                              style={{ color: 'blue', cursor: 'pointer' }}
                                              onClick={() => toggleFeedback(items._id, 'investor')}
                                            >
                                              {expandedFeedback[items._id]?.investor ? ' Read Less' : '... Read More'}
                                            </span>
                                          )}
                                        </>
                                      )}
                                      {showCompFeedBck === "Y" && (
                                        <>
                                          {expandedFeedback[items._id]?.company
                                            ? items.companyFeedback
                                            : items?.companyFeedback?.substring(0, 100)}{' '}
                                          {items?.companyFeedback?.length > 100 && (
                                            <span
                                              style={{ color: 'blue', cursor: 'pointer' }}
                                              onClick={() => toggleFeedback(items._id, 'company')}
                                            >
                                              {expandedFeedback[items._id]?.company ? ' Read Less' : '... Read More'}
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : isLoading ? <SkeltonList row={10} col={4} /> : <tr> 
                                <td colspan="5" className="img-center" ><p>No data to show</p></td> 
                            </tr>}
                          </tbody>
                        </table>
                        
                        <div className="table_pagination_sales_order">
                          <Pagination
                            count={TOTALPAGES}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </VendorList>
          </Box>
        </div>
      </div>
    </>
  );
}
