import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, notification, Drawer, Button, Space } from "antd";
import { FaEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import TobNavBar from "../../pages/DashBaord/Top_navbar";
import { Link, useNavigate } from "react-router-dom";
import ConfirmPopup from './ConfirmPopUp';
import ImportPopUp from './ImportPopUp';

import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  LikeOutlined,
  DislikeOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined
} from "@ant-design/icons";

import moment from "moment";
import { ASSETS_BASE_URL } from "../../../src/config/constants";
import SkeltonList from "../../../src/components/SkeltonEffect/list";
import {
    list,
  chnageStatus,changePiority
} from "../../../src/controllers/interests/requestsController";
import { getPageMore, statusInterestMessage } from "../../../src/controllers/common";

import { FaPlusSquare } from "react-icons/fa";

function VendorList(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

VendorList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {

  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [importPopUpOpen, setImportPopUpOpen] = useState(false);
  const toggleImporPopUp = () => {
    setImportPopUpOpen(!importPopUpOpen);
  };

  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () => {
    setFilterShow(!isFilterShow);
  } 

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  /************************************************************************************* */
  const [isLoading, setIsLoading] =useState(true);
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [deleteId, setDeleteId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(20);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: "",
    to: new Date(),
    filter_by: "",
    search: "",
  }); //End

  const [showRequest, setShowRequest] = useState("");
  const [totalrequestedcount, settotalrequestedcount] = useState("");

  /*********************************************************
   *  This function is use to fetch investors list
   *********************************************************/
  const getList = async () => {
    try {
      setIsLoading(true);
      setListData([]);
      const options = {
        type: "",
        condition: {
          ...(showRequest ? { status: showRequest } : null),
        },
        ...(filter?{filter:filter}:null),
        select: {},
        sort: { status: 1, _id: -1 },
        skip: SKIP ? SKIP : 0,
        limit: LIMIT ? LIMIT : 20,
      };
      const listData = await list(options);
      if (listData.status === true) {
        if (listData.result.length > 0) {
          setListData(listData?.result);
        } else {
          setListData([]);
        }
        const countOptions = {
          type: "count",
          condition: {
            ...(showRequest ? { status: showRequest } : null),
          },
          ...(filter?{filter:filter}:null)
        };
        const count = await list(countOptions);
        settotalrequestedcount(count?.result);
        setTotalPages(getPageMore(count?.result));
      } else {
        setListData([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }; //End


  /*********************************************************
   *  This function is for handle page change
   *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage - 1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };


  /*********************************************************
   *  This function is use to change investor status
   *********************************************************/
  const handleStatusChnage = async (status = "", id) => {
    try {
      setListData([]);
      const options = {
        url: `requests/change-status`,
        postData: {
          id: id,
          status: status,
        },
      };
      const res = await chnageStatus(options);
      if (res.status === true) {
        notification.open({
          message: "Data updated.",
          description: `Status updated successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
      } else {
        notification.open({
          message: "Opps!",
          description: `${res?.message}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      notification.open({
        message: "Opps!",
        description: `Operation not perform yet! please try in some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
    getList();
  };


  /*********************************************************
   *  This function is use to change investor status
   *********************************************************/
  const handlePriorityChnage = async (priority = "", id) => {
    try {
      setListData([]);
      const options = {
        url: `requests/change-priority`,
        postData: {
          id: id,
          priority: priority,
        },
      };
      const res = await changePiority(options);
      if (res.status === true) {
        notification.open({
          message: "Data updated.",
          description: `Priority updated successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
      } else {
        notification.open({
          message: "Opps!",
          description: `${res?.message}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      notification.open({
        message: "Opps!",
        description: `Operation not perform yet! please try in some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
    getList();
  };



  const dropdownMenu = (items) => {
    return (
      <>
        <Menu>
          {items?.status !== 'A' && (
            <Menu.Item
              key={`01`}
              onClick={() => {
                handleStatusChnage("A", items._id);
              }}
            >
              <LikeOutlined /> <span className="show_span">Accept</span>
            </Menu.Item>
          )}
        {items?.status !== 'R' && (
          <Menu.Item
            key={`02`}
            onClick={() => {
              handleStatusChnage("R", items._id);
            }}
          >
            <DislikeOutlined /> <span className="show_span">Decline</span>
          </Menu.Item>
        )}
        {items?.status !== 'P' && (
          <Menu.Item
            key={`03`}
            onClick={() => {
              handleStatusChnage("P", items._id);
            }}
          >
            <InfoCircleOutlined /> <span className="show_span">Pending</span>
          </Menu.Item>
        )}

        {items?.status !== 'C' && (
          <Menu.Item
            key={`04`}
            onClick={() => {
              handleStatusChnage("C", items._id);
            }}
          >
            <InfoCircleOutlined /> <span className="show_span">Cancel</span>
          </Menu.Item>
        )}

          {items?.investor_priority === 1 && (
            <Menu.Item
              key={`05`}
              onClick={() => {
                handlePriorityChnage(2, items._id);
              }}
            >
              <ArrowDownOutlined /> <span className="show_span">Set Priority to 2</span>
            </Menu.Item>
          )}
          {items?.investor_priority === 2 && (
            <Menu.Item
              key={`05`}
              onClick={() => {
                handlePriorityChnage(1, items._id);
              }}
            >
              <ArrowUpOutlined /> <span className="show_span">Set Priority to 1</span>
            </Menu.Item>
          )}
        </Menu>
      </>
    );
  };

  /*********************************************************
   *  This function is ued for handle filter input chnage
   *********************************************************/
  const handleFilterReset = () => {
    try {
      setFilter('');
      inputRef1.current.value = '';
      inputRef2.current.value = '';
      inputRef3.current.value = '';
      inputRef4.current.value = '';
    } catch (error) {
      
    }
  }

  const handleFilterApply = (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      setFilter((pre)=>({
        ...pre,
        'filter_by' : form.get('filter_by') || '',
        'search'    : form.get('search') || '',
        'to'        : form.get('to_date') || '',
        'from'      : form.get('from_date') || '',

      }))
      // console.log('filter',filter);
      // alert('submit');
      handleFiterDrawer();
      setSkip(0);
    } catch (error) {
      
    }
  }

  /*********************************************************
   *  This function will load when page load and with dependency update
   *********************************************************/
  useEffect(() => {
    getList();
    targetRef.current.scrollIntoView({
      behavior:'smooth',
    });
    document.title = "Chiratae Super Admin || Interests List";
  }, [currentPage, showRequest, popUpOpen, filter, importPopUpOpen]);

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <TobNavBar title={"Interest List"}></TobNavBar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                      onClick={() => {
                        setShowRequest("");
                        setSkip(0);
                      }}
                    />
                    <Tab
                      label="Pending"
                      className="tabs_main_contnet"
                      {...a11yProps(1)}
                      onClick={() => {
                        setShowRequest("P");
                        setSkip(0);
                      }}
                    />
                    <Tab
                      label="Accepted"
                      className="tabs_main_contnet"
                      {...a11yProps(2)}
                      onClick={() => {
                        setShowRequest("A");
                        setSkip(0);
                      }}
                    />
                    <Tab
                      label="Rejected"
                      className="tabs_main_contnet"
                      {...a11yProps(3)}
                      onClick={() => {
                        setShowRequest("R");
                        setSkip(0);
                      }}
                    />
                    <Tab
                      label="Cancelled By Admin"
                      className="tabs_main_contnet"
                      {...a11yProps(4)}
                      onClick={() => {
                        setShowRequest("C");
                        setSkip(0);
                      }}
                    />
                  </Tabs>
                </div>

                <div className="fa_filter_rating_review filter_img_and_add_btn_main">
                  <div className="inventory_filter_div" onClick={handleFiterDrawer}>
                    <img
                      src={ASSETS_BASE_URL + "./images/Filter.svg"}
                      alt="filter"
                      className="user_iconses"
                    />
                    <Link
                      to={false}
                      className="filter_btn_invontry_rating_review"
                    >
                      Filters
                    </Link>
                  </div>
                  <div onClick={togglePopUp}>
                    <button type="button" class="btn btn-dark">
                      <span className="request_new_categories">Schedule Meetings</span>
                    </button>
                  </div>

                  <div onClick={toggleImporPopUp}>
                    <button type="button" class="btn btn-dark">
                      <span className="request_new_categories">Import interests</span>
                    </button>
                  </div>

                </div>
              </div>
            </Box>
            {/* First Inventory */}
            <VendorList 
              //value={value} index={0}
              >
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    {/* <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold common_inventory_heading">
                        All Vendor's List
                      </h6>
                    </div> */}
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            { totalrequestedcount ? (
                              <tr><th style={{color:"red", fontSize:"18px",fontWeight:"600"}} colSpan={2}>{`Total - ${totalrequestedcount}`}</th></tr>
                            ):null}                            
                            <tr>
                              <th style={{width:"8%"}}>Sr no.</th>
                              <th>Request Id</th>
                              <th>Investor's Firm</th>
                              <th>Investor</th>
                              <th>Company</th>
                              <th>Investor Priority</th>
                              <th>Created At</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr key={index}>
                                    <td>{(currentPage - 1) * 20 + index + 1}</td>
                                    <td className="admin_user_list_date"> {items?.resuest_id} </td>
                                    <td className="admin_user_list_date"> {items?.investor_data?.investor_firm_name} </td>
                                    <td className="admin_user_list_date">
                                      {items?.investor_data?.investor_first_name} {" "}{items?.investor_data?.investor_last_name}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {" "}
                                      {items?.company_data?.company_name}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {(items?.investor_priority === 1 || items?.investor_priority === '1')?'First':'Second'}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {moment(`${items?.createdAt}`).format(
                                        "DD/MM/YYYY, h:m A"
                                      )}{" "}
                                    </td>
                                    <td
                                      className="admin_user_list_date"
                                      dangerouslySetInnerHTML={{
                                        __html: statusInterestMessage(items.status),
                                      }}
                                    ></td>
                                    <td>
                                      <Dropdown
                                        overlay={() => dropdownMenu(items)}
                                        placement="bottomLeft"
                                        arrow
                                      >
                                        <HiOutlineDotsHorizontal style={{cursor:"pointer"}}/>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : isLoading ? <SkeltonList row={10} col={9} /> : <tr> 
                                <td colspan="5" className="img-center" ><p>No data to show</p></td> 
                            </tr>}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination
                            count={TOTALPAGES}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </VendorList>
          </Box>
        </div>
        <ConfirmPopup
          popUpOpen={popUpOpen}
          togglePopUp={togglePopUp}
        />
        <ImportPopUp
          popUpOpen={importPopUpOpen}
          togglePopUp={toggleImporPopUp}
        />
      </div>
      {/* Filter */}
      <Drawer
        title="Interests Filter"
        placement={`right`}
        width={500}
        onClose={handleFiterDrawer}
        open={isFilterShow}
        extra={
          <Space>
            <Button onClick={handleFiterDrawer}>Cancel</Button>
            <Button type="primary" onClick={handleFilterReset}>
              Reset
            </Button>
          </Space>
        }
      >
        <div className="responsive">
          <div className="row">
            <form id='filter_form' onSubmit={handleFilterApply}>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="investor_first_name" className="all_section_for_label_main_class" >
                  Search Field :
                </label>
                <select name="filter_by" className="form-control store_input_field" id="filter_by" ref={inputRef1}>
                  <option value=''>Select search field</option>
                  <option value='investor_firm_name' selected={filter?.filter_by ==='investor_firm_name'?true:false}>Investor Firm</option>
                  <option value='investor_first_name' selected={filter?.filter_by ==='investor_first_name'?true:false}>Investor First Name</option>
                  <option value='investor_last_name' selected={filter?.filter_by ==='investor_last_name'?true:false}>Investor Last Name</option>
                  <option value='company_name' selected={filter?.filter_by ==='company_name'?true:false}>Company Name</option>
                  <option value='investor_email' selected={filter?.filter_by ==='investor_email'?true:false}>Investor Email</option>
                  <option value='company_email' selected={filter?.filter_by ==='company_email'?true:false}>Company Email</option>
                  <option value='investor_priority' selected={filter?.filter_by ==='investor_priority'?true:false}>Priority</option>
                  {/* <option value='status' selected={filter?.filter_by ==='status'?true:false}>Status</option> */}
                </select>
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="investor_first_name" className="all_section_for_label_main_class" >
                  Search Text :
                </label>
                <input
                  title="Enter search text."
                  placeholder="Enter search text."
                  type="text"
                  name="search"
                  className="form-control store_input_field"
                  id="search"  
                  ref={inputRef2}
                  // onChange={handleFilterChnage} 
                  // value={filter?.search}             
                />
              </div>
              {/* <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="investor_first_name" className="all_section_for_label_main_class" >
                  From :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="from_date"
                  className="form-control store_input_field"
                  id="from_date"      
                  ref={inputRef3}
                  // onChange={handleFilterChnage}          
                />
              </div> */}
              {/* <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="investor_first_name" className="all_section_for_label_main_class" >
                  To :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="to_date"
                  className="form-control store_input_field"
                  id="to_date"   
                  ref={inputRef4}
                  // onChange={handleFilterChnage}             
                />
              </div> */}
              <br/>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  title="Enter search text."
                  type="submit"
                  className="form-control btn btn-black"    
                  style={{ backgroundColor: "black", color: "white" }}           
                >Apply</button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </>
  );
}
