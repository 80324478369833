import axios from "axios";

/*********************************************************
* Function Name : sendWelcomeMail
* Description   : used to send welcome mail to all users
* By            : Sumit Bedwal
* Date          : 04-03-2024 
*********************************************************/
export const sendWelcomeMail = async (options) =>{
    // console.log('options--',options);
    try{
        const url = 'https://chiratae.algoapi.in/apiv2/sendmail/registration';
        const headers = { 'Authorization' : 'KztpEM93Xq25ubxhFLHdsT4raVYJPBckygAn68U7' };
        return await axios.post(url, options, { headers });
    } catch(error){
        return error;
    }
};//End of Function


/*********************************************************
* Function Name : sendCompanyBroadcastMail
* Description   : used to send company broadcast email to all investors
* By            : Sumit Bedwal
* Date          : 04-03-2024 
*********************************************************/
export const sendCompanyBroadcastMail = async (options) =>{
    // console.log('company broad cast options--',options);
    try{
        const url = 'https://chiratae.algoapi.in/apiv2/sendmail/newcompanyaddedbroadcast';
        const headers = { 
                'Authorization' : 'KztpEM93Xq25ubxhFLHdsT4raVYJPBckygAn68U7',
                'Content-Type'  :  'application/json'
            };
        return await axios.post(url, options, { headers });
    } catch(error){
        return error;
    }
};//End of Function


/*********************************************************
* Function Name : sendEventBroadcastMail
* Description   : used to send event broadcast email to all users
* By            : Sumit Bedwal
* Date          : 04-03-2024 
*********************************************************/
export const sendEventBroadcastMail = async (options) =>{
    // console.log('event broadcast options--',options);
    try{
        const url = 'https://chiratae.algoapi.in/apiv2/sendmail/eventbroadcast';
        const headers = { 
                'Authorization' : 'KztpEM93Xq25ubxhFLHdsT4raVYJPBckygAn68U7',
                'Content-Type'  :  'application/json'
            };
        return await axios.post(url, options, { headers });
    } catch(error){
        return error;
    }
};//End of Function



/*********************************************************
* Function Name : sendWelcomeMail
* Description   : used to send welcome mail to users
* By            : Sumit Bedwal
* Date          : 04-03-2024 
*********************************************************/
export const sendMeetingCancelledMail = async (options) =>{
    // console.log('meeting cancelled options--',options);
    try{
        const url = 'https://chiratae.algoapi.in/apiv2/sendmail/meetingcancelled';
        const headers = { 
                'Authorization' : 'KztpEM93Xq25ubxhFLHdsT4raVYJPBckygAn68U7',
                'Content-Type'  :  'application/json'
            };
        return await axios.post(url, options, { headers });
    } catch(error){
        return error;
    }
};//End of Function


/*********************************************************
* Function Name : sendWelcomeMail
* Description   : used to send welcome mail to users
* By            : Sumit Bedwal
* Date          : 04-03-2024 
*********************************************************/
export const sendMeetingScheduleMail = async () =>{
    // console.log('event broadcast options--',options);

    const options = {
        send: "yes"
    }
    try{
        const url = 'https://chiratae.algoapi.in/apiv2/sendmail/schedulesend';
        const headers = { 
                'Authorization' : 'KztpEM93Xq25ubxhFLHdsT4raVYJPBckygAn68U7',
                'Content-Type'  :  'application/json'
            };
        return await axios.post(url, options, { headers });
    } catch(error){
        return error;
    }
};//End of Function
