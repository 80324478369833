import {API_ADMIN_URL} from '../../config/constants'
import { postRequest } from '../API'
import axios from "axios";


/*********************************************************
* Function Name : getInvestorCumulativeReportSheet
* Description   : get investor cumulative reports
* By            : Sumit Bedwal
* Date          : 04-03-2024 
*********************************************************/
export const getInvestorCumulativeReportSheet = async () =>{
    // console.log('options--',options);
    try{
        const url = 'https://chiratae.algoapi.in/apiv2/exceldownload/investorcumulative';
        const headers = { 'Authorization' : 'KztpEM93Xq25ubxhFLHdsT4raVYJPBckygAn68U7' };
        return await axios.post(url, {}, { headers });
        // const reees = await axios.post(url, {}, { headers });
    } catch(error){
        return error;
    }
};//End of Function



/*********************************************************
* Function Name : getCompanyCumulativeReportSheet
* Description   : get company cumulative reports
* By            : Sumit Bedwal
* Date          : 04-03-2024 
*********************************************************/
export const getCompanyCumulativeReportSheet = async () =>{
    // console.log('options--',options);
    try{
        const url = 'https://chiratae.algoapi.in/apiv2/exceldownload/companycumulative';
        const headers = { 'Authorization' : 'KztpEM93Xq25ubxhFLHdsT4raVYJPBckygAn68U7' };
        return await axios.post(url, {}, { headers });
        // const reees = await axios.post(url, {}, { headers });
    } catch(error){
        return error;
    }
};//End of Function
