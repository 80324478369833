import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, notification, Drawer, Button, Space, Modal } from "antd";
import { FaEdit, FaEye, FaRegEnvelope, FaArrowsAltV } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import TobNavBar from "../DashBaord/Top_navbar";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";


import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  LikeOutlined,
  DislikeOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  CalendarOutlined,
  CloseCircleOutlined,
  EyeOutlined
} from "@ant-design/icons";

import moment from "moment";
import { ASSETS_BASE_URL, COMPANY_LOGIN_URL, INVESTOR_LOGIN_URL } from "../../config/constants";
import SkeltonList from "../../components/SkeltonEffect/list";
import {list , chnageStatus, getCurrentMeetingSheet} from "../../controllers/meetings/meetingsController";
import { getPageMore, statusInterestMessage } from "../../controllers/common";
import { sendMeetingCancelledMail } from "../../controllers/sendMail/mailcontroller";

import { FaPlusSquare } from "react-icons/fa";

function VendorList(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

VendorList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {

  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const handleCancel = () => {
    togglePopUp();
    // cancelReason();
  };

  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () => {
    setFilterShow(!isFilterShow);
  } 

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  /************************************************************************************* */
  const [isLoading, setIsLoading] =useState(true);
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [deleteId, setDeleteId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(20);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: "",
    to: new Date(),
    filter_by: "",
    search: "",
  }); //End

  const [showRequest, setShowRequest] = useState("");
  const [showResReq, setShowResReq] = useState("");
  const [showCanReq, setShowCanReq] = useState("");
  const [cancelBy, setCancelBy] = useState("");
  const [cancelReason, setCancelReason] = useState("");
  const [totalmeetcount, settotalMeetcount] = useState("");
  const [sortByMeetId, setSortByMeetId] = useState(1);


  const handleEdit = async (item = {}) => {
    navigate("/meetings/addeditdata", { state: item });
  };

  const handleMeetIdSort = () => {
    // setSortByName('');
    setSortByMeetId((prevSort) => (prevSort === -1 ? 1 : -1));
  }


  /*********************************************************
   *  This function is use to fetch investors list
   *********************************************************/
  const getList = async () => {
    try {
      setIsLoading(true);
      setListData([]);
      const options = {
        type: "",
        condition: {
          ...(showRequest ? { status: showRequest } : null),
          ...(showResReq ? { isReschedule: showResReq } : ""),
          ...(showCanReq ? { isCancelled: showCanReq } : ""),
        },
        ...(filter?{filter:filter}:null),
        select: {},
        // sort: {  meet_id: 1 },
        sort: { status: 1,
          ...(sortByMeetId ? { meet_id: sortByMeetId } : { meet_id: 1 }),
              },
        skip: SKIP ? SKIP : 0,
        limit: LIMIT ? LIMIT : 20,
      };
      const listData = await list(options);
      if (listData.status === true) {
        if (listData.result.length > 0) {
          setListData(listData?.result);
        } else {
          setListData([]);
        }
        const countOptions = {
          type: "count",
          condition: {
            ...(showRequest ? { status: showRequest } : null),
            ...(showResReq ? { isReschedule: showResReq } : ""),
            ...(showCanReq ? { isCancelled: showCanReq } : ""),
          },
          ...(filter?{filter:filter}:null)
        };
        const count = await list(countOptions);
        settotalMeetcount(count?.result);
        setTotalPages(getPageMore(count?.result));
      } else {
        setListData([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }; //End


  /*********************************************************
   *  This function is for handle page change
   *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage - 1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const setCancelItems = (data) => {
    setPopUpOpen(true);
    setCancelBy(data?.cancel_by);
    setCancelReason(data?.reason);
  }


  /*********************************************************
   *  This function is use to change investor status
   *********************************************************/
  const handleStatusChnage = async (status = "", item) => {
    try {
      setListData([]);
      const options = {
        url: `meetings/changestatus`,
        postData: {
          id: item?._id,
          status: status,
        },
      };
      const res = await chnageStatus(options);
      if (res.status === true) {
        // handleMeetingCancelledMail(item);
        notification.open({
          message: "Data updated.",
          description: `Status updated successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
      } else {
        notification.open({
          message: "Opps!",
          description: `${res?.message}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      console.log('error',error);
      notification.open({
        message: "Opps!",
        description: `Operation not perform yet! please try in some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
    getList();
  };

  /************************************************************************
   *  This function is for sending welcome mail of meeting cancelled to user
   ************************************************************************/
  const handleMeetingCancelledMail = async (params = "") => {
    try {
        const options = {
            mailtype: "meetingcancelled",
            portfolioname: params?.company_id?.company_name,
            portfolioemail: params?.company_id?.company_email,
            portfoliologinurl: COMPANY_LOGIN_URL, 
            investorname: params?.investor_id?.investor_first_name +' '+ params?.investor_id?.investor_last_name,
            investoremail: params?.investor_id?.investor_email,
            investorloginurl: INVESTOR_LOGIN_URL, 
            meetid: params?.meet_id,
            date: params?.date,
            timeslot: params?.time_from +' to '+params?.time_to 
        }

      const res = await sendMeetingCancelledMail(options);
      if (res.request.status === 200) {
        notification.open({
          message: "Mail sent.",
          description: `Meeting cancelled mail sent successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
      } else {
        notification.open({
          message: "Opps!",
          description: 'Failed to send email, please try again in sometime.',
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      notification.open({
        message: "Opps!",
        description: `Operation not performed yet! please try in some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
    getList();
  } 


  /*********************************************************
   *  This function is use to change investor status
   *********************************************************/
  // const handlePriorityChnage = async (priority = "", id) => {
  //   try {
  //     setListData([]);
  //     const options = {
  //       url: `requests/change-priority`,
  //       postData: {
  //         id: id,
  //         priority: priority,
  //       },
  //     };
  //     const res = await changePiority(options);
  //     if (res.status === true) {
  //       notification.open({
  //         message: "Data updated.",
  //         description: `Priority updated successfully.`,
  //         placement: "topRight",
  //         icon: <CheckCircleOutlined style={{ color: "green" }} />,
  //         duration: 2,
  //       });
  //     } else {
  //       notification.open({
  //         message: "Opps!",
  //         description: `${res?.message}`,
  //         placement: "topRight",
  //         icon: <InfoCircleOutlined style={{ color: "red" }} />,
  //         duration: 2,
  //       });
  //     }
  //   } catch (error) {
  //     notification.open({
  //       message: "Opps!",
  //       description: `Operation not perform yet! please try in some time.`,
  //       placement: "topRight",
  //       icon: <InfoCircleOutlined style={{ color: "red" }} />,
  //       duration: 2,
  //     });
  //   }
  //   getList();
  // };

  //////////////Modal/////////
  const [cancelItem, setcancelItem] = useState({});
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const showCancelMeetModal = (item) => {
    setcancelItem(item);
    setIsCancelModalOpen(true);
  };

  const handleCancelMeet = () => {
    handleStatusChnage("CANCELLED", cancelItem);
    setIsCancelModalOpen(false);
  }

  const handleClosepopu = () => {
    setIsCancelModalOpen(false);
  };
  ///////Modal/////////////////



  const dropdownMenu = (items) => {
    return (
      <>
        <Menu>
        {items?.status === 'SCHEDULED' ? (
            <Menu.Item
              key={`01`}
              onClick={() => handleEdit(items)}
            >
              <CalendarOutlined /> <span className="show_span">Reschedule</span>
            </Menu.Item>
        ): null}
            {/* {items?.isCancelled === 'Y' ? (
              <Menu.Item
                key={`02`}
                onClick={() => handleStatusChnage("CANCELLED", items)}
              >
                <CloseCircleOutlined /> <span className="show_span">Cancel</span>
              </Menu.Item>
            ): null} */}

            {/* {items?.isCancelled === 'Y' ? (
              <Menu.Item
                key={`02`}
                onClick={() => showCancelMeetModal(items)}
              >
                <CloseCircleOutlined /> <span className="show_span">Cancel</span>
              </Menu.Item>
            ): null} */}
            {items?.status != 'CANCELLED' ? (
            <Menu.Item
                key={`02`}
                onClick={() => showCancelMeetModal(items)}
              >
                <CloseCircleOutlined /> <span className="show_span">Cancel</span>
              </Menu.Item>
              ): null}

            {items?.status === 'CANCELLED' ? (
            <Menu.Item
                key={`03`}
                onClick={() => {
                  handleStatusChnage("SCHEDULED", items);
                }}
              >
                <CheckCircleOutlined /> <span className="show_span">Schedule Again</span>
              </Menu.Item>
              ): null}

            {/* {items?.isCancelled === 'Y' ? ( 
              <Menu.Item
                key={`03`}
                onClick={() => setCancelItems(items)}
              >
                <EyeOutlined/> <span className="show_span">View reason</span>
              </Menu.Item>
            ): null} */}
            {/* {items?.isCancelled === 'Y' ? (
              <Menu.Item
                key={`04`}
                onClick={() => sendMeetingCancelledMail(items)}
              >
                <FaRegEnvelope/> <span className="show_span">View reason</span>
              </Menu.Item>
            ): null} */}
            
        </Menu>
      </>
    );
  };

  const handleCreateMeeting = async () => {
    navigate("/meetings/create");
  };



  const handleViewMeetingSheet = async() => {
    const excelres = await getCurrentMeetingSheet();
    console.log('excelres',excelres);
    if(excelres?.request.status === 200){
      // const excellink = excelres?.data;
      // Open the link in a new tab
      window.open(excelres?.data, '_blank');
    } else {
      console.log('error hai');
    }
  }

  /*********************************************************
   *  This function is ued for handle filter input chnage
   *********************************************************/
  const handleFilterReset = () => {
    try {
      setFilter('');
      inputRef1.current.value = '';
      inputRef2.current.value = '';
      inputRef3.current.value = '';
      inputRef4.current.value = '';
    } catch (error) {
      
    }
  }

  const handleFilterApply = (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      setFilter((pre)=>({
        ...pre,
        'filter_by' : form.get('filter_by') || '',
        'search'    : form.get('search') || '',
        'to'        : form.get('to_date') || '',
        'from'      : form.get('from_date') || '',

      }))
      // console.log('filter',filter);
      // alert('submit');
      handleFiterDrawer();
      setSkip(0);
    } catch (error) {
      
    }
  }

  /*********************************************************
   *  This function will load when page load and with dependency update
   *********************************************************/
  useEffect(() => {
    getList();
    targetRef.current.scrollIntoView({
      behavior:'smooth',
    });
    document.title = "Chiratae Super Admin || Events List";
  }, [currentPage, showRequest,showResReq,showCanReq,sortByMeetId, popUpOpen, filter]);

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <TobNavBar title={"Meetings List"}></TobNavBar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                      onClick={() => {
                        setShowRequest("");
                        setShowResReq("");
                        setShowCanReq("");
                      }}
                    />
                    <Tab
                      label="Scheduled"
                      className="tabs_main_contnet"
                      {...a11yProps(1)}
                      onClick={() => {
                        setShowRequest("SCHEDULED");
                        setShowResReq("");
                        setShowCanReq("");
                      }}
                    />
                    <Tab
                      label="Cancelled"
                      className="tabs_main_contnet"
                      {...a11yProps(2)}
                      onClick={() => {
                        setShowRequest("CANCELLED");
                        setShowResReq("");
                        setShowCanReq("");
                      }}
                    />
                    <Tab
                      label="Reschedule Req."
                      className="tabs_main_contnet"
                      {...a11yProps(3)}
                      onClick={() => {
                        setShowRequest("");
                        setShowResReq("Y");
                        setShowCanReq("");
                      }}
                    />
                    <Tab
                      label="Cancellation Req."
                      className="tabs_main_contnet"
                      {...a11yProps(4)}
                      onClick={() => {
                        setShowRequest("");
                        setShowCanReq("");
                        setShowCanReq("Y");
                      }}
                    />
                  </Tabs>
                </div>

                <div className="fa_filter_rating_review filter_img_and_add_btn_main">
                  <div className="inventory_filter_div" onClick={handleFiterDrawer}>
                    <img
                      src={ASSETS_BASE_URL + "./images/Filter.svg"}
                      alt="filter"
                      className="user_iconses"
                    />
                    <Link
                      to={false}
                      className="filter_btn_invontry_rating_review"
                    >
                      Filters
                    </Link>
                  </div>
                  {/* <div>
                    <Link to={'https://chiratae.algoapi.in/apiv2/exceldownload/latest'} target="_blank" >
                    <button type="button" class="btn btn-primary">
                      <span className="request_new_categories">Initial Meeting Sheet</span>
                    </button>
                    </Link>
                  </div> */}
                  <div onClick={()=> handleViewMeetingSheet()}>
                    <button type="button" class="btn btn-primary">
                      <span className="request_new_categories">Current Meeting Sheet</span>
                    </button>
                  </div>
                  <div onClick={()=> handleCreateMeeting()}>
                    <button type="button" class="btn btn-info">
                      <span className="request_new_categories text-white">Create a meeting</span>
                    </button>
                  </div>
                </div>
              </div>
            </Box>
            {/* First Inventory */}
            <VendorList 
              //value={value} index={0}
              >
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    {/* <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold common_inventory_heading">
                        All Vendor's List 
                      </h6>
                    </div> */}
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            { totalmeetcount ? (
                              <tr><th style={{color:"red", fontSize:"18px",fontWeight:"600"}} colSpan={2}>{`Total - ${totalmeetcount}`}</th></tr>
                            ):null} 
                            <tr>
                              <th style={{width:"8%"}}>Sr no.</th>
                              <th style={{width:"8%"}}>Meet Id <FaArrowsAltV onClick={()=> handleMeetIdSort()} style={{ cursor:"pointer"}}/></th>
                              {/* <th>id</th> */}
                              <th>Investor's Firm</th>
                              <th>Investor</th>
                              <th>Company</th>
                              <th>Priority</th>
                              <th>Table</th>
                              <th>Slot</th>
                              <th>Time</th>
                              <th>Date</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr key={index}>
                                    <td>{(currentPage - 1) * 20 + index + 1}</td>
                                    <td className="admin_user_list_date"> {items?.meet_id} </td>
                                    {/* <td>{items?._id}</td> */}
                                    <td className="admin_user_list_date">
                                    {items?.investor_id?.investor_firm_name}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {items?.investor_id?.investor_first_name} {" "}{items?.investor_id?.investor_last_name}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {" "}
                                      {items?.company_id?.company_name}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {(items?.priority === 1 || items?.priority === '1')?'First':'Second'}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {items?.table}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {items?.time_slot}
                                    </td>
                                    <td id="meetdate"
                                      className="admin_user_list_date">
                                        {items?.time_from} {" - "} {items?.time_to}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {items?.date}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {items?.status}
                                    </td>
                                    <td>
                                      <Dropdown
                                        overlay={() => dropdownMenu(items)}
                                        placement="bottomLeft"
                                        arrow
                                      >
                                        <HiOutlineDotsHorizontal style={{cursor:"pointer"}}/>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : isLoading ? <SkeltonList row={10} col={12} /> : <tr> 
                                <td colspan="5" className="img-center" ><p>No data to show</p></td> 
                            </tr>}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination
                            count={TOTALPAGES}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </VendorList>
          </Box>
        </div>
        {/* <VendorListDeletePopUp
          popUpOpen={popUpOpen}
          togglePopUp={togglePopUp}
          deleteId={deleteId}
          setDeleteId={setDeleteId}
        /> */}
      </div>
      {/* Filter */}
      <Drawer
        title="Meetings Filter"
        placement={`right`}
        width={500}
        onClose={handleFiterDrawer}
        open={isFilterShow}
        extra={
          <Space>
            <Button onClick={handleFiterDrawer}>Cancel</Button>
            <Button type="primary" onClick={handleFilterReset}>
              Reset
            </Button>
          </Space>
        }
      >
        <div className="responsive">
          <div className="row">
            <form id='filter_form' onSubmit={handleFilterApply}>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="investor_first_name" className="all_section_for_label_main_class" >
                  Search Field :
                </label>
                <select name="filter_by" className="form-control store_input_field" id="filter_by" ref={inputRef1}>
                  <option value=''>Select search field</option>
                  <option value='investor_firm_name' selected={filter?.filter_by ==='investor_firm_name'?true:false}>Investor Firm</option>
                  <option value='investor_first_name' selected={filter?.filter_by ==='investor_first_name'?true:false}>Investor First Name</option>
                  <option value='investor_last_name' selected={filter?.filter_by ==='investor_last_name'?true:false}>Investor Last Name</option>
                  <option value='company_name' selected={filter?.filter_by ==='company_name'?true:false}>Company Name</option>
                  <option value='investor_email' selected={filter?.filter_by ==='investor_email'?true:false}>Investor Email</option>
                  <option value='company_email' selected={filter?.filter_by ==='company_email'?true:false}>Company Email</option>
                  <option value='priority' selected={filter?.filter_by ==='priority'?true:false}>Priority</option>
                  <option value='time_from' selected={filter?.filter_by ==='time_from'?true:false}>Start Time</option>
                  {/* <option value='status' selected={filter?.filter_by ==='status'?true:false}>Status</option> */}
                </select>
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="investor_first_name" className="all_section_for_label_main_class" >
                  Search Text :
                </label>
                <input
                  title="Enter search text."
                  placeholder="Enter search text."
                  type="text"
                  name="search"
                  className="form-control store_input_field"
                  id="search"  
                  ref={inputRef2}
                  // onChange={handleFilterChnage} 
                  // value={filter?.search}             
                />
              </div>
              {/* <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="investor_first_name" className="all_section_for_label_main_class" >
                  From :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="from_date"
                  className="form-control store_input_field"
                  id="from_date"      
                  ref={inputRef3}         
                />
              </div> */}
              {/* <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="investor_first_name" className="all_section_for_label_main_class" >
                  To :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="to_date"
                  className="form-control store_input_field"
                  id="to_date"   
                  ref={inputRef4}
                  // onChange={handleFilterChnage}             
                />
              </div> */}
              <br/>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  title="Enter search text."
                  type="submit"
                  className="form-control btn btn-black"    
                  style={{ backgroundColor: "black", color: "white" }}           
                >Apply</button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>

      <Modal title="Confirm !" open={isCancelModalOpen} onCancel={handleClosepopu}
        footer={[
          <Button key="close" type="primary" onClick={handleClosepopu}>
            Close
          </Button>,
          <Button key="ok" type="primary" onClick={handleCancelMeet} danger>
          Yes,cancel
        </Button>
         ]}
         >
        <h5>Do you want to cancel this meeting ?</h5>
      </Modal>

      <Dialog
      open={popUpOpen}
      onClose={togglePopUp}
      maxWidth="md"
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer" style={{padding:"30px"}}>
        <div className="myMainDrawerClass">
          <Typography
            variant="p"
            component="div"
            gutterBottom
            className="myFilterClass"
            style={{marginLeft:"30px"}}
          >
            <b>Cancelled By</b> - {cancelBy}
          </Typography>
          {/* <IconButton onClick={togglePopUp}>
            <IoMdClose />
          </IconButton> */}
        </div>
        <Typography
          variant="body1"
          component="div"
          className="my_delete_popup_paragraph"
        >
          <p><b>Reason</b> - {cancelReason}</p>
        </Typography>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </div>
    </Dialog>
    </>
  );
}
