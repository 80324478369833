import React, { useEffect, useState } from "react";
import { Upload, Flex, Radio, notification, InputNumber, Select } from "antd";
import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import {
  addeditdata,
  chnageStatus,
  categoryList,
} from "../../../controllers/vendors/vendorsController";
import { fetchIpAddress } from "../../../controllers/API";

import LoadingEffect from "../../../components/Loading/LoadingEffect";
import { beforeUpload } from "../../../controllers/common";

const UserListEditPage = () => {
  const { Option } = Select;
  const { Dragger } = Upload;

  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [error, setError] = useState([]);
  const [ADDEDITDATA, setAddEditSata] = useState(state ? state : []);
  const [productCat, setProductCat] = useState([]);
  const [serviceCat, setServiceCat] = useState([]);
  const [productCatAddEditData, setProductCatAddEditData] = useState([]);
  const [serviceCatAddEditData, setServiceCatAddEditData] = useState([]);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [image, setImage] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    // vendor_account_number: ADDEDITDATA?.vendor_account_number || "",
  });
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  /*********************************************************
   *  This function is use to handle imput chnage
   *********************************************************/
  const handleChange = (e) => {
    setAddEditSata((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
    setError((pre) => ({
      ...pre,
      [e.target.name]: "",
    }));
  };
  /*********************************************************
   *  This function is use to handle image
   *********************************************************/
  const handleImageChnage = (e) => {
    setImage(e.fileList);
  };
  /*********************************************************
   *  This function is use to handle genger checkbox chnage
   *********************************************************/
  // const onCheckedChange = (e) => {
  //   setAddEditSata((pre) => ({
  //     ...pre,
  //     vendor_gender: e.target.value,
  //   }));
  // };

  /*********************************************************
   *  This function is used to handle phone number change
   *********************************************************/
  const handlePhoneChange = (value) => {
    setAddEditSata((prev) => ({
      ...prev,
      company_phone: value,
    }));
    setError((prev) => ({
      ...prev,
      company_phone: "", // Clear previous error message
    }));
  };

  /*********************************************************
   *  This function is used to handle phone code change
   *********************************************************/
  const handlePhoneCodeChange = (value) => {
    setAddEditSata((prev) => ({
      ...prev,
      vendor_country_code: value,
      company_phone: "", // Clear phone number
    }));
    setError((prev) => ({
      ...prev,
      company_phone: "", // Clear previous error message
    }));
  };

  const handleProductcategory = (value) => {
    console.log("value", value);
    setProductCatAddEditData(value);
    setError((preError) => ({
      ...preError,
      product_category: "",
    }));
  };

  const handleServicesCategory = (value) => {
    setServiceCatAddEditData(value);
    setError((preError) => ({
      ...preError,
      service_category: "",
    }));
  };

  /*********************************************************
   *  This function is use to handle form submit
   *********************************************************/
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const {
        _id,
        company_name,
        company_founder_name,
        company_ceo_name,
        company_email,
        company_phone,
        company_stage,
        company_sector,
        company_description,
      } = ADDEDITDATA;
      const ipAddress = await fetchIpAddress();

      const form = new FormData();
      if (_id && _id !== "undefined" && _id !== null) {
        form.append("edit_id", _id);
      }
      form.append("name", company_name?company_name:'');
      form.append("founder_name", company_founder_name?company_founder_name:'');
      form.append("ceo_name", company_ceo_name?company_ceo_name:'');
      form.append("email", company_email?company_email:'');
      form.append("phone", company_phone?company_phone:null);
      form.append("stage", company_stage?company_stage:'');
      form.append("sector", company_sector?company_sector:'');
      form.append("description", company_description?company_description:'');
      if (image.length > 0) {
        form.append("image", image[0]?.originFileObj);
      }

      form.append("ipAddress", ipAddress);

      const isValidate = validateFormData(form);

      if (isValidate) {
        const options = {
          url: "accounts/company/addeditdata",
          postData: form,
        };
        const res = await addeditdata(options);
        if (res.status === true) {
          notification.open({
            message: "Data updated!",
            description: `Data updated successfully.`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          navigate("/company/list");
        } else {
          notification.open({
            message: "Oops!",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      } else {
        notification.open({
          message: "Oops!",
          description: `Please enter the required fields.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /*********************************************************
   *  This function is used to validate form data before submit
   *********************************************************/
  const validateFormData = (formData) => {
    // const companyname = /^[A-Za-z0-9\s]+$/;
    const name = /^[A-Za-z\s]+$/; 
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const numberPattern = /^[6-9][0-9]{9}$/;
    const stagePattern = /^[1-9]+$/;

    if (!formData.get("name")?.trim() || formData.get("name") === '' || formData.get("name") === 'undefined' ) {
      setError((prevError) => ({
        ...prevError,
        company_name: "Company name is required",
      }));
      return false;
    }
    // if(!companyname.test(formData.get("name"))){
    //   setError((prevError) => ({
    //     ...prevError,
    //     company_name: "Please enter a valid company name",
    //   }));
    //   return false;
    // }

    if (!formData.get("founder_name")?.trim() || formData.get("founder_name") === '' || formData.get("founder_name") === 'undefined') {
      setError((prevError) => ({
        ...prevError,
        company_founder_name: "Founder name is required",
      }));
      return false;
    }
    if(!name.test(formData.get("founder_name"))){
      setError((prevError) => ({
        ...prevError,
        company_founder_name: "Please enter a valid founder name",
      }));
      return false;
    }
    // if (!formData.get("ceo_name")?.trim() || formData.get("ceo_name") ==='' || formData.get("ceo_name") === 'undefined') {
    //   setError((prevError) => ({
    //     ...prevError,
    //     company_ceo_name: "CEO name is required",
    //   }));
    //   return false;
    // }
    // if(!name.test(formData.get("ceo_name"))){
    //   setError((prevError) => ({
    //     ...prevError,
    //     company_ceo_name: "Please enter a valid ceo name",
    //   }));
    //   return false;
    // }
    if (!formData.get("email")?.trim() || formData.get("email") === '' || formData.get("email") === 'undefined') {
      setError((prevError) => ({
        ...prevError,
        company_email: "Email id is required",
      }));
      return false;
    }
    if (!emailPattern.test(formData.get("email"))) {
      setError((prevError) => ({
        ...prevError,
        company_email: "Please enter a valid email id",
      }));
      return false;
    }
    const myPhone = formData.get("phone");
    if(myPhone != 'null'){
      console.log('myPhone',myPhone);
      if(!numberPattern.test(myPhone)){
        setError((prevError) => ({
          ...prevError,
          company_phone: "Invalid phone",
        }));
        return false;
      }
    }
    if (!formData.get("stage")?.trim() || formData.get("stage") === '' || formData.get("stage") === 'undefined') {
      setError((prevError) => ({
        ...prevError,
        company_stage: "Stage is required",
      }));
      return false;
    }
    // if (!stagePattern.test(formData.get("stage"))) {
    //   setError((prevError) => ({
    //     ...prevError,
    //     company_stage: "Please enter a valid stage",
    //   }));
    //   return false;
    // }
    if (!formData.get("sector")?.trim() || formData.get("sector") === '' || formData.get("sector") === 'undefined') {
      setError((prevError) => ({
        ...prevError,
        company_sector: "Sector is required",
      }));
      return false;
    }
    if (!formData.get("description")?.trim() || formData.get("description") === '' || formData.get("description") === 'undefined') {
      setError((prevError) => ({
        ...prevError,
        company_description: "Description is required",
      }));
      return false;
    }

    // const myPhone = formData.get("phone");
    // if(myPhone){
    //   if(!numberPattern.test(myPhone)){
    //     setError((prevError) => ({
    //       ...prevError,
    //       company_phone: "Invalid phone",
    //     }));
    //     return false;
    //   }
    // }
    // if (
    //   !phone?.trim()) {
    //   setError((prevError) => ({
    //     ...prevError,
    //     company_phone: !phone.trim()
    //       ? "Phone number is required"
    //       : `Phone number must be exactly 10 digits`,
    //   }));
    //   return false;
    // }
    return true;
  };

  /*********************************************************
   *  This function is use to handle delete account
   *********************************************************/
  const handleDelete = async () => {
    try {
      const options = {
        url: `accounts/investors/changestatus`,
        postData: {
          user_id: ADDEDITDATA?._id,
          status: "D",
        },
      };
      const res = await chnageStatus(options);
      if (res.status === true) {
        notification.open({
          message: "User deleted successfully.",
          description: `Data delete successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
        navigate("/users/list");
      } else {
        notification.open({
          message: "Oops!",
          description: `${res?.message}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      notification.open({
        message: "Oops!",
        description: `Operation not perform yet! please try in some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
  };


  return (
    <div>
      {" "}
      <div className="container-fluid">
        <div className="Inventory_edit_page_main_div">
          <div>
            <h5 className="inventory_edit_page_heading">
              {ADDEDITDATA?._id ? `Edit` : `Add`} Companies
            </h5>
          </div>
          <Link to="/company/list">
            <div>
              <button type="button" className="btn btn-secondary">
                <ArrowLeftOutlined /> Back
              </button>
            </div>
          </Link>
        </div>

        <div className="card shadow mb-4 dataBase_table_Add_Data">
          <div className="card-body">
            <div className="responsive">
              <form method="post" className="inventory_form_main_contnet">
                <div className="row inventory_form_main_content_form_section">
                  <div className="row">
                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="company_name"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>Name :{" "}
                      </label>
                      <input
                        title="Company Name"
                        placeholder="Enter Company name"
                        type="text"
                        name="company_name"
                        className="form-control store_input_field"
                        id="company_name"
                        value={ADDEDITDATA?.company_name}
                        onChange={handleChange}
                      />
                      {error.company_name ? (
                        <p style={{ color: "red" }}>
                          {error.company_name}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="company_founder_name"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>Founder Name :{" "}
                      </label>
                      <input
                        title="Enter Founder name."
                        placeholder="Enter Founder name."
                        type="text"
                        name="company_founder_name"
                        className="form-control store_input_field"
                        id="company_founder_name"
                        value={ADDEDITDATA?.company_founder_name}
                        onChange={handleChange}
                      />
                      {error.company_founder_name ? (
                        <p style={{ color: "red" }}>{error.company_founder_name}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="company_ceo_name"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>CEO Name :{" "}
                      </label>
                      <input
                        title="Enter CEO name."
                        placeholder="Enter CEO name."
                        type="text"
                        name="company_ceo_name"
                        className="form-control store_input_field"
                        id="company_ceo_name"
                        value={ADDEDITDATA?.company_ceo_name}
                        onChange={handleChange}
                      />
                      {error.company_ceo_name ? (
                        <p style={{ color: "red" }}>{error.company_ceo_name}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="company_email"
                        className="all_section_for_label_main_class  all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Email Address :
                      </label>
                      <input
                        title="Enter email address."
                        placeholder="Enter email address."
                        type="text"
                        name="company_email"
                        className="form-control store_input_field"
                        id="company_email"
                        value={ADDEDITDATA?.company_email}
                        onChange={handleChange}
                      />
                      {error.company_email ? (
                        <p style={{ color: "red" }}>{error.company_email}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="company_phone"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Phone :
                      </label>
                      <input
                        title="Enter Phone no."
                        placeholder="Enter Phone no."
                        type="number"
                        name="company_phone"
                        className="form-control store_input_field"
                        id="company_phone"
                        value={ADDEDITDATA?.company_phone}
                        onChange={handleChange}
                      />
                      {error.company_phone ? (
                        <p style={{ color: "red" }}>
                          {error.company_phone}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="company_stage"
                        className="all_section_for_label_main_class  all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Company Stage :
                      </label>
                      <input
                        title="Enter stage"
                        placeholder="Enter stage"
                        type="text"
                        name="company_stage"
                        className="form-control store_input_field"
                        id="company_stage"
                        value={ADDEDITDATA?.company_stage}
                        onChange={handleChange}
                      />
                      {error.company_stage ? (
                        <p style={{ color: "red" }}>{error.company_stage}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* <hr /> */}
                  <div className="row">                
                    <div className="form-group col-lg-12 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="company_sector"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Sector :
                      </label>
                      <input
                        title="Enter sector"
                        placeholder="Enter sector"
                        type="text"
                        name="company_sector"
                        className="form-control store_input_field"
                        id="company_sector"
                        value={ADDEDITDATA?.company_sector}
                        onChange={handleChange}
                      />
                      {error.company_sector ? (
                        <p style={{ color: "red" }}>
                          {error.company_sector}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data">
                    <label
                      htmlFor="company_description"
                      className="all_section_for_label_main_class"
                    >
                      Description :
                    </label>
                    <textarea
                      title="Enter description"
                      rows="5"
                      name="company_description"
                      className="form-control store_input_field"
                      id="company_description"
                      value={ADDEDITDATA?.company_description}
                      onChange={handleChange}
                    />
                    {error.company_description ? (
                      <p style={{ color: "red" }}>{error.company_description}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="all_section_for_label_main_class"
                  >
                    {!ADDEDITDATA?._id && (
                      <span style={{ color: "red" }}>*</span>
                    )}
                    Profile Image
                  </label>
                  <Dragger
                    className="about_us_dragger_file"
                    maxCount="1"
                    // listType="picture"
                    onChange={handleImageChnage}
                    beforeUpload={beforeUpload}
                  >
                    <button
                      type="button"
                      className="about_us_dragger_file_button"
                    >
                      Upload file
                    </button>
                  </Dragger>
                  <p
                    style={{ color: "blue" }}
                    className="all_top_and_bottom_for_input_text"
                  >
                    *Note: Please select an image of format (JPEG/JPG/PNG).
                  </p>
                  {error.image ? (
                    <p style={{ color: "red" }}>{error.image}</p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="inventory_edit_submit_btn">
                  <button
                    type="button"
                    className="btn btn-primary inventory_save_button"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <LoadingEffect isLoading={isLoading} /> */}
    </div>
  );
};

export default UserListEditPage;
