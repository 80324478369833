import React, { useState, useEffect } from "react";
import "./main_dashboard.css";
import Top_navbar from "./Top_navbar";
import Recent_order from "./Recent_order";
import { Select, Skeleton } from "antd";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { getDashboardData } from '../../controllers/users/usersControllet';
import { RiBarChartHorizontalLine, RiArrowDropRightLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { FaUserTie, FaUsers, FaHouseUser, FaArrowRight, FaRegThumbsUp, } from 'react-icons/fa';
import { VscAccount, VscFeedback } from "react-icons/vsc";
import { PiHandshakeLight } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';


const Dashboard = () => {
  // User's Count Section
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setuserDate] = useState('');
  const [totaluser, setTotalUser] = useState('');
  const [orderData, setOrderDate] = useState('');
  const [revenueData, setRevenueDate] = useState('');
  const [salesData, setSalesDate] = useState('');
  const [ordersDetails, setOrdersDetails] = useState('');

  const [TOTALUSERSCOUNT, setTotalUsersCount] = useState('');
  const [TOTALORDERSCOUNT, setTotalordersCount] = useState('');
  const [TOTALREVENUECOUNT, setTotalRevenueCount] = useState('');
  const [TOTALSALECOUNT, setTotalSaleCount] = useState('');

  // const [usersShowRequest, setusersShowRequest] = useState("");

  const [investorShowRequest, setinvestorShowRequest] = useState("");
  const [companyShowRequest, setcompanyShowRequest] = useState("");


  const [investorData, setInvestorData] = useState("");
  const [companyData, setCompanyData] = useState("");
  const [totalInvestor, setTotalInvestor] = useState("");
  const [totalCompany, setTotalCompany] = useState("");


  /************** */
  const [TOTALINVESTORSCOUNT, setTotalInvestorsCount] = useState('');
  const [TOTALCOMPANIESCOUNT, setCompanyTotalCount] = useState('');
  const [TOTALINTERESTSCOUNT, setInterestsTotalCount] = useState('');
  const [TOTALMEETINGSCOUNT, setMeetingsTotalCount] = useState('');
  const [TOTALINVFEEDBCKCOUNT, setInvestorFeedBckTotalCount] = useState('');
  const [TOTALCOMPFEEDBCKCOUNT, setCompanyFeedBckTotalCount] = useState('');



  // const [TOTALINVESTORINMEETING, setTotalInvestorinMeeting] = useState('');
  // const [TOTALCOMPANYINMEETING, setTotalCompanyinMeeting] = useState('');
  const [TOTALACTIVEINVESTOR, setTotalActiveInvestor] = useState('');
  const [TOTALACTIVECOMPANY, setTotalActiveCompany] = useState('');
  /************** */

  // const handleUserShowRequest = async (value) => {
  //   if (value === 'Active') {
  //     setinvestorShowRequest("A");
  //     const activeUser = investorData.find(item => item.status === "A")?.count;
  //     setTotalInvestorsCount(activeUser || 0);
  //   } else if (value === 'Inactive') {
  //     setinvestorShowRequest("I");
  //     const inactiveUser = investorData.find(item => item.status === "I")?.count;
  //     setTotalInvestorsCount(inactiveUser || 0);
  //   } else if (value === 'Delete') {
  //     setinvestorShowRequest("D");
  //     const deletedUser = investorData.find(item => item.status === "D")?.count;
  //     setTotalInvestorsCount(deletedUser || 0);
  //   } else {
  //     setinvestorShowRequest("");
  //     setTotalInvestorsCount(totalInvestor);
  //   }
  // }


  // const handleCompanyShowRequest = async (value) => {
  //   if (value === 'Active') {
  //     setcompanyShowRequest("A");
  //     const activeCompany = companyData.find(item => item.status === "A")?.count;
  //     setCompanyTotalCount(activeCompany || 0);
  //   } else if (value === 'Inactive') {
  //     setcompanyShowRequest("I");
  //     const inactiveCompany = companyData.find(item => item.status === "I")?.count;
  //     setCompanyTotalCount(inactiveCompany || 0);
  //   } else if (value === 'Delete') {
  //     setcompanyShowRequest("D");
  //     const deletedCompany = companyData.find(item => item.status === "D")?.count;
  //     setCompanyTotalCount(deletedCompany || 0);
  //   } else {
  //     setcompanyShowRequest("");
  //     setCompanyTotalCount(totalCompany);
  //   }
  // }


  const getData = async () => {
    try {
      setIsLoading(true);
      const res = await getDashboardData();


      setInvestorData(res?.investors || '');
      setTotalInvestorsCount(res?.totalInvestors || 0);
      setTotalInvestor(res?.totalInvestors || 0);

      setCompanyData(res?.companies || '');
      setCompanyTotalCount(res?.totalCompanies || 0);
      setTotalCompany(res?.totalCompanies || 0);


      setInterestsTotalCount(res?.totalInterests || 0);

      setMeetingsTotalCount(res?.totalMeetings || 0);
      setInvestorFeedBckTotalCount(res?.investorsFeedback || 0);
      setCompanyFeedBckTotalCount(res?.companyFeedback || 0);

      // setTotalInvestorinMeeting(res?.meetingFirmCount || 0);
      // setTotalCompanyinMeeting(res?.meetingCompanyCount || 0);

      setTotalActiveInvestor( res?.activeInvestorCount || 0);
      setTotalActiveCompany( res?.activeCompanyCount || 0);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
    // console.log('res',res);
  }
  useEffect(() => {
    getData();
  }, [])

  return (
    <>
      <Top_navbar></Top_navbar>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 px-0">
            <div className="transaction_form mt-5">
              <div className="col-md-4" onClick={()=>navigate('/investors/list')} style={{cursor:"pointer"}}>
                  <div className="card shadow-sm h-100" >
                    <div className="card-body text-center dashboard_card_body_centre position-relative">
                      <div className="dashboard_order_icon">
                        <div className="mb-3">
                          <FaUserTie size={60} style={{ color: '#b62533' }} />
                        </div>
                        <h5 className="card-title" style={{textDecoration:"none !important"}}>{TOTALACTIVEINVESTOR}</h5>
                        <h5 className="card-title" style={{textDecoration:"none !important"}}>Investing Firms This Event</h5>
                      </div>
                      {/* <p className="card-text" style={{textDecoration:"none !important"}}>Total number of registered investors.</p> */}
                      <FaArrowRight size={20} className="position-absolute dashboard_svg_scg" style={{ top: "10px", right: "10px" }} />
                    </div>
                  </div>
              </div>


              <div className="col-md-4" onClick={()=>navigate('/company/list')} style={{cursor:"pointer"}}>
                  <div className="card shadow-sm h-100" >
                    <div className="card-body text-center dashboard_card_body_centre position-relative">
                      <div className="dashboard_order_icon">
                        <div className="mb-3">
                          <FaUsers size={60} style={{ color: '#b62533' }} />
                        </div>
                        <h5 className="card-title" style={{textDecoration:"none !important"}}>{TOTALACTIVECOMPANY}</h5>
                        <h5 className="card-title" style={{textDecoration:"none !important"}}>Companies This Event</h5>
                      </div>
                      {/* <p className="card-text" style={{textDecoration:"none !important"}}>Total number of registered investors.</p> */}
                      <FaArrowRight size={20} className="position-absolute dashboard_svg_scg" style={{ top: "10px", right: "10px" }} />
                    </div>
                  </div>
              </div>


              {/* <div className="col-md-4" onClick={()=>navigate('/events/list')} style={{cursor:"pointer"}}>
                <div className="card shadow-sm h-100">
                  <div className="card-body text-center dashboard_card_body_centre position-relative">
                    <div className="dashboard_order_icon">
                      <div className="mb-3">
                        <FaHouseUser size={60} style={{ color: '#b62533' }} />
                      </div>
                      <h5 className="card-title">Event</h5>
                    </div>
                    <p className="card-text">Manage Event date,time and venue.</p>
                    <FaArrowRight size={20} className="position-absolute dashboard_svg_scg" style={{ top: "10px", right: "10px" }} />
                  </div>
                </div>
              </div> */}

              <div className="col-md-4" onClick={()=>navigate('/requests-sent/list')} style={{cursor:"pointer"}}>
                <div className="card shadow-sm h-100">
                  <div className="card-body text-center dashboard_card_body_centre position-relative">
                    <div className="dashboard_order_icon">
                      <div className="mb-3">
                        <FaRegThumbsUp size={60} style={{ color: '#b62533' }} />
                      </div>
                      <h5 className="card-title">{TOTALINTERESTSCOUNT}</h5>
                      <h5 className="card-title">Meeting Interests</h5>
                    </div>
                    <p className="card-text">Total number of interests shown by investors</p>
                    <FaArrowRight size={20} className="position-absolute dashboard_svg_scg" style={{ top: "10px", right: "10px" }} />
                  </div>
                </div>
              </div>

              <div className="col-md-4 mt-4" onClick={()=>navigate('/meetings/list')} style={{cursor:"pointer"}}>
                <div className="card shadow-sm h-100">
                  <div className="card-body text-center dashboard_card_body_centre position-relative">
                    <div className="dashboard_order_icon">
                      <div className="mb-3">
                        <PiHandshakeLight size={60} style={{ color: '#b62533' }} />
                      </div>
                      <h5 className="card-title">{TOTALMEETINGSCOUNT}</h5>
                      <h5 className="card-title">Meetings</h5>
                    </div>
                    <p className="card-text">Total number of meetings generated</p>
                    <FaArrowRight size={20} className="position-absolute dashboard_svg_scg" style={{ top: "10px", right: "10px" }} />
                  </div>
                </div>
              </div>

              {/* <div className="col-md-4 mt-4" onClick={()=>navigate('/feedbacks/list')} style={{cursor:"pointer"}}>
                <div className="card shadow-sm h-100">
                  <div className="card-body text-center dashboard_card_body_centre position-relative">
                    <div className="dashboard_order_icon">
                      <div className="mb-3">
                        <VscFeedback size={60} style={{ color: '#b62533' }} />
                      </div>
                      <h5 className="card-title">{TOTALINVFEEDBCKCOUNT}</h5>
                      <h5 className="card-title">Investors Feedback</h5>
                    </div>
                    <p className="card-text">View feedbacks from investors</p>
                    <FaArrowRight size={20} className="position-absolute dashboard_svg_scg" style={{ top: "10px", right: "10px" }} />
                  </div>
                </div>
              </div>

              <div className="col-md-4 mt-4" onClick={()=>navigate('/feedbacks/list')} style={{cursor:"pointer"}}>
                <div className="card shadow-sm h-100">
                  <div className="card-body text-center dashboard_card_body_centre position-relative">
                    <div className="dashboard_order_icon">
                      <div className="mb-3">
                        <VscFeedback size={60} style={{ color: '#b62533' }} />
                      </div>
                      <h5 className="card-title">{TOTALCOMPFEEDBCKCOUNT}</h5>
                      <h5 className="card-title">Companies Feedback</h5>
                    </div>
                    <p className="card-text">View feedbacks from companies</p>
                    <FaArrowRight size={20} className="position-absolute dashboard_svg_scg" style={{ top: "10px", right: "10px" }} />
                  </div>
                </div>
              </div> */}

              {/* <div className="col-md-4 mt-4" onClick={()=>navigate('/feedbacks/list')} style={{cursor:"pointer"}}>
                <div className="card shadow-sm h-100">
                  <div className="card-body text-center dashboard_card_body_centre position-relative">
                    <div className="dashboard_order_icon">
                      <div className="mb-3">
                        <VscFeedback size={60} style={{ color: '#b62533' }} />
                      </div>
                      <h5 className="card-title">{TOTALCOMPFEEDBCKCOUNT + TOTALINVFEEDBCKCOUNT}</h5>
                      <h5 className="card-title">Feedbacks Received</h5>
                    </div>
                    <p className="card-text">View feedbacks from investors & companies</p>
                    <FaArrowRight size={20} className="position-absolute dashboard_svg_scg" style={{ top: "10px", right: "10px" }} />
                  </div>
                </div>
              </div> */}

            </div>


              <div className="transaction_form mt-5">
                <div className="col-md-4" onClick={()=>navigate('/investors/list')} style={{cursor:"pointer"}}>
                    <div className="card shadow-sm h-100" >
                      <div className="card-body text-center dashboard_card_body_centre position-relative">
                        <div className="dashboard_order_icon">
                          <div className="mb-3">
                            <FaUserTie size={60} style={{ color: '#b62533' }} />
                          </div>
                          <h5 className="card-title" style={{textDecoration:"none !important"}}>{TOTALINVESTORSCOUNT}</h5>
                          <h5 className="card-title" style={{textDecoration:"none !important"}}>Registered Investors</h5>
                        </div>
                        <p className="card-text" style={{textDecoration:"none !important"}}>Total number of registered investors.</p>
                        <FaArrowRight size={20} className="position-absolute dashboard_svg_scg" style={{ top: "10px", right: "10px" }} />
                      </div>
                    </div>
                </div>
                <div className="col-md-4" onClick={()=>navigate('/company/list')} style={{cursor:"pointer"}}>
                  <div className="card shadow-sm h-100">
                    <div className="card-body text-center dashboard_card_body_centre position-relative">
                      <div className="dashboard_order_icon">
                        <div className="mb-3">
                          <FaUsers size={60} style={{ color: '#b62533' }} />
                        </div>
                        <h5 className="card-title">{TOTALCOMPANIESCOUNT}</h5>
                        <h5 className="card-title">Registered Companies</h5>
                      </div>
                      <p className="card-text">Total number of registered Portfolio companies</p>
                      <FaArrowRight size={20} className="position-absolute dashboard_svg_scg" style={{ top: "10px", right: "10px" }} />
                    </div>
                  </div>
                </div>
              </div>


          </div>
        </div>

        {/* tables */}
        <div className="row">
          <div className="col-md-12">
            {/* <Recent_order 
              orderList = {ordersDetails}
              isLoading = {isLoading}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
