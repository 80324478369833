import React, { useEffect, useState } from "react";
import { Upload, Flex, Radio, notification, InputNumber, Select } from "antd";
import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import {
  addeditdata,
} from "../../../src/controllers/events/eventsController";
import { fetchIpAddress } from "../../../src/controllers/API";


const SlotsEditPage = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [error, setError] = useState([]);
  const [ADDEDITDATA, setAddEditSata] = useState(state ? state : []);

  const [passwordVisible, setPasswordVisible] = useState(false);

  // const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    vendor_account_number: ADDEDITDATA?.vendor_account_number || "",
  });
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  /*********************************************************
   *  This function is use to handle imput chnage
   *********************************************************/
  const handleChange = (e) => {
    setAddEditSata((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
    setError((pre) => ({
      ...pre,
      [e.target.name]: "",
    }));
  };


  /*********************************************************
   *  This function is use to handle form submit
   *********************************************************/
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const {
        _id,
        avg_meet_count_from,
        avg_meet_count_to,
        meet_duration
      } = ADDEDITDATA;
      const ipAddress = await fetchIpAddress();

      const form = new FormData();
      if (_id && _id !== "undefined" && _id !== null) {
        form.append("edit_id", _id);
      }
      form.append("avg_meet_count_from", avg_meet_count_from);
      form.append("avg_meet_count_to", avg_meet_count_to);
      form.append("meet_duration", meet_duration);
      form.append("ipAddress", ipAddress);

      const isValidate = validateFormData(form);

      const data = {
        edit_id: _id,
        avg_meet_count_from: avg_meet_count_from,
        avg_meet_count_to: avg_meet_count_to,
        meet_duration: meet_duration,
        ipAddress: ipAddress
      };

      if (isValidate) {
        const options = {
          url: "events/addedit-avg-settings",
          postData: data,
        };
        const res = await addeditdata(options);
        if (res.status === true) {
          notification.open({
            message: "Data updated!",
            description: `Data updated successfully.`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          navigate("/events/avg-settings-list");
        } else {
          notification.open({
            message: "Opps!",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      } else {
        notification.open({
          message: "Opps!",
          description: `Please enter the required fields.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /*********************************************************
   *  This function is used to validate form data before submit
   *********************************************************/
  const validateFormData = (formData) => {

    if (!formData.get("avg_meet_count_from")?.trim()) {
      setError((prevError) => ({
        ...prevError,
        avg_meet_count_from: "Avg meet count from is required",
      }));
      return false;
    }
    if (!formData.get("avg_meet_count_to")?.trim()) {
      setError((prevError) => ({
        ...prevError,
        avg_meet_count_to: "Avg meet count to is required",
      }));
      return false;
    }
    if (!formData.get("meet_duration")?.trim()) {
      setError((prevError) => ({
        ...prevError,
        meet_duration: "Meet duration is required",
      }));
      return false;
    }
    return true;
  };



  return (
    <div>
      {" "}
      <div className="container-fluid">
        <div className="Inventory_edit_page_main_div">
          <div>
            <h5 className="inventory_edit_page_heading">
              {ADDEDITDATA?._id ? `Edit` : `Add`} Avg. Settings
            </h5>
          </div>
          <Link to="/events/avg-settings-list">
            <div>
              <button type="button" className="btn btn-secondary">
                <ArrowLeftOutlined /> Back
              </button>
            </div>
          </Link>
        </div>

        <div className="card shadow mb-4 dataBase_table_Add_Data">
          <div className="card-body">
            <div className="responsive">
              <form method="post" className="inventory_form_main_contnet">
                <div className="row inventory_form_main_content_form_section">
                  <div className="row">
                    <div className="form-group col-lg-4 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="avg_meet_count_from"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Avg Meet Count From :{" "}
                      </label>
                      <input
                        title="Avg Meet Count From"
                        type="number"
                        placeholder="Avg Meet Count From"
                        name="avg_meet_count_from"
                        className="form-control store_input_field"
                        id="avg_meet_count_from"
                        value={ADDEDITDATA?.avg_meet_count_from}
                        onChange={handleChange}
                        min={0}
                      />
                      {error.avg_meet_count_from ? (
                        <p style={{ color: "red" }}>
                          {error.avg_meet_count_from}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-4 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="avg_meet_count_to"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Avg Meet Count To :
                      </label>
                      <input
                        title="Avg Meet Count To"
                        placeholder="Avg Meet Count To"
                        type="number"
                        name="avg_meet_count_to"
                        className="form-control store_input_field"
                        id="avg_meet_count_to"
                        value={ADDEDITDATA?.avg_meet_count_to}
                        onChange={handleChange}
                        min={1}
                      />
                      {error.avg_meet_count_to ? (
                        <p style={{ color: "red" }}>
                          {error.avg_meet_count_to}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-4 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="meet_duration"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Meet Duration (in minutes) :{" "}
                      </label>
                      <input
                        title="Meet Duration"
                        placeholder="Meet Duration"
                        type="number"
                        name="meet_duration"
                        className="form-control store_input_field"
                        id="meet_duration"
                        value={ADDEDITDATA?.meet_duration}
                        onChange={handleChange}
                        min={1}
                      />
                      {error.meet_duration ? (
                        <p style={{ color: "red" }}>{error.meet_duration}</p>
                      ) : (
                        ""
                      )}
                    </div>

                  </div>
                </div>

                <div className="inventory_edit_submit_btn">
                  <button
                    type="button"
                    className="btn btn-primary inventory_save_button"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <LoadingEffect isLoading={isLoading} /> */}
    </div>
  );
};

export default SlotsEditPage;
