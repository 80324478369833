import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, notification, Drawer, Button, Space } from "antd";
import { FaEdit, FaEye, FaRegEnvelope , FaSortAlphaDown,FaSortAlphaUp, FaArrowsAltV,FaPlusSquare , FaUpload} from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import TobNavBar from "../../DashBaord/Top_navbar";
import VendorListDeletePopUp from "../VendorList/VendorListDeletePopUp";
import { Link, useNavigate } from "react-router-dom";
import "../UserList/UserList.css";
import { Flex, Spin } from "antd";
import {COMPANY_LOGIN_URL, INVESTOR_LOGIN_URL} from "../../../config/constants";

import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  LikeOutlined,
  DislikeOutlined,
} from "@ant-design/icons";

import moment from "moment";
import { ASSETS_BASE_URL } from "../../../config/constants";
import SkeltonList from "../../../components/SkeltonEffect/list";
import {
  list,
  chnageStatus,
} from "../../../controllers/company/companyController";
import {list as investorList} from "../../../controllers/investors/investorController";
import { getPage, statusMessage } from "../../../controllers/common";

import {sendWelcomeMail, sendCompanyBroadcastMail} from "../../../controllers/sendMail/mailcontroller";

function VendorList(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

VendorList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {

  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () => {
    setFilterShow(!isFilterShow);
  } 

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  /************************************************************************************* */
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [deleteId, setDeleteId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // const [allCompanyData, setAllCompanyListData] = useState([]);
  const [totalcompanycountread, settotalcompanycountread] = useState("");
  const [emailCompleteStatus, setEmailStatus ] = useState(0);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: "",
    to: new Date(),
    filter_by: "",
    search: "",
  }); //End

  const [showRequest, setShowRequest] = useState("");

  const handleEdit = async (item = {}) => {
    navigate("/company/addeditdata", { state: item });
  };

  const [sortByname, setSortByName] = useState("");
  const [sortByDate, setSortByDate] = useState(-1);


  /*********************************************************
   *  This function is use to fetch investors list
   *********************************************************/
  const getList = async () => {
    try {
      setIsLoading(true);
      setListData([]);
      const options = {
        type: "",
        condition: {
          ...(showRequest ? { status: showRequest } : null),
        },
        ...(filter?{filter:filter}:null),
        select: {},
        sort: { 
          status: 1,
          ...(sortByname ? { company_name: sortByname === 'Y' ? 1 : -1 } : { _id: sortByDate }), 
        },
        skip: SKIP ? SKIP : 0,
        limit: LIMIT ? LIMIT : 10,
      };
      const listData = await list(options);
      if (listData.status === true) {
        if (listData.result.length > 0) {
          setListData(listData?.result);
        } else {
          setListData([]);
        }
        const countOptions = {
          type: "count",
          condition: {
            ...(showRequest ? { status: showRequest } : null),
          },
          ...(filter?{filter:filter}:null)
        };
        const count = await list(countOptions);
        settotalcompanycountread(count?.result);
        setTotalPages(getPage(count?.result));
      } else {
        setListData([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }; //End


  // Handle name sorting
  const handleNameSort = () => {
    setSortByName((prevSort) => (prevSort === 'Y' ? 'N' : 'Y'));
  };

  // Handle date sorting
  const handleDateSort = () => {
    setSortByName('');
    setSortByDate((prevSort) => (prevSort === -1 ? 1 : -1));
  }


  /*********************************************************
   *  This function is for handle page change
   *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage - 1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };


  /*********************************************************
   *  This function is use to change investor status
   *********************************************************/
  const handleStatusChnage = async (status = "", id) => {
    try {
      setListData([]);
      const options = {
        url: `accounts/company/changestatus`,
        postData: {
          user_id: id,
          status: status,
        },
      };
      const res = await chnageStatus(options);
      if (res.status === true) {
        notification.open({
          message: "Data updated.",
          description: `Data updated successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
      } else {
        notification.open({
          message: "Opps!",
          description: `${res?.message}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      notification.open({
        message: "Opps!",
        description: `Operation not performed yet! please try in some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
    getList();
  };


  /*********************************************************
   *  This function is for sending welcome mail to investors
   *********************************************************/
  const handleSendWelMail = async (params = "") => {
    try {
      setListData([]);

        const options = {
            mailtype: "registration",
            email: params?.company_email,
            name: params?.company_name,
            account: params?.company_email,
            accounttype: "Portfolio Company",
            loginurl: COMPANY_LOGIN_URL
        }

      const res = await sendWelcomeMail(options);
      if (res.request.status === 200) {
        notification.open({
          message: "Mail sent.",
          description: `Mail sent successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
      } else {
        notification.open({
          message: "Opps!",
          description: `${res?.message}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      notification.open({
        message: "Opps!",
        description: `Operation not performed yet! please try in some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
    getList();
  } 

  const handleBulkMail = async () => {
    try {
      // setIsLoading(true);
      const listData = await list();
      if(listData?.result.length > 0 ){
        sendEmailsSequentially(listData?.result);
      }

    } catch (error) {
      console.log(error);
      notification.open({
        message: "Opps!",
        description: `Operation not performed yet! please try in some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
    getList();
  } 

  const  sendEmailsSequentially = async (allCompanyData) => {
    // console.log('allCompanyData',allCompanyData);
    const totalEmails = allCompanyData.length; // Total number of emails to send
    let completedEmails = 0; // Counter for the number of completed emails

    for (const item of allCompanyData) {
      const options = {
        mailtype: "registration",
        email: item?.company_email,
        name: item?.company_name,
        account: item?.company_email,
        accounttype: "Portfolio Company",
        loginurl: COMPANY_LOGIN_URL,
      };
  
      // console.log("Sending email to:", options.email);
      // console.log('list page options', options);
  
      try {
        await sendWelcomeMail(options); // Wait for the email to be sent
        completedEmails++; // Increment the counter after a successful email is sent

        // Calculate and log the completion percentage
        // const completionPercentage = ((completedEmails / totalEmails) * 100).toFixed(2);
        const completionPercentage = Math.floor((completedEmails / totalEmails) * 100);
        setEmailStatus(completionPercentage);
      } catch (error) {
        console.log("Error sending email:", error);
      }
    }
  
    // console.log("All emails have been processed.");
    notification.open({
      message: "Mail sent.",
      description: `Mail sent successfully.`,
      placement: "topRight",
      icon: <CheckCircleOutlined style={{ color: "green" }} />,
      duration: 2,
    });
  }


  const handleBroadCastMail = async (companyName='') => {
    // alert(companyName);
      try {  
        const investorData = await investorList();
        // console.log('my investor list ',investorData);
        if(investorData?.result.length > 0 ){
          const investoremaillist = [];
          const total_investor_email = investorData?.result; 
          for (const item of total_investor_email) {
            const investordetail = {
              investorname : item?.investor_first_name +' '+item?.investor_last_name,
              investoremail : item?.investor_email
            }
            investoremaillist.push(investordetail);
          }

          const options = {
            mailtype: "registration",
            newcompanyname: companyName,
            loginurl: INVESTOR_LOGIN_URL,
            emaillist: investoremaillist
          };
          // console.log('investoremaillist options',options);

          const sendbroadcastmail = await sendCompanyBroadcastMail(options);
          console.log('mail api res',sendbroadcastmail);
          if(sendbroadcastmail?.request.status === 200){
            notification.open({
              message: "Mail sent.",
              description: `Mail sent successfully.`,
              placement: "topRight",
              icon: <CheckCircleOutlined style={{ color: "green" }} />,
              duration: 2,
            });
            getList();
          } else {
            notification.open({
              message: "Opps!",
              description: `Operation not performed yet! please try in some time.`,
              placement: "topRight",
              icon: <InfoCircleOutlined style={{ color: "red" }} />,
              duration: 2,
            });
            getList();
          }

        } else {
          notification.open({
            message: "Opps!",
            description: "No investor found!",
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      } catch (error) {
        notification.open({
          message: "Opps!",
          description: `Operation not performed yet! please try in some time.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
      getList();
    }  



  const dropdownMenu = (items) => {
    return (
      <>
        <Menu>
          <Menu.Item key="01" onClick={() => handleEdit(items)}>
            <FaEdit />
            <span className="show_span_edit">Edit</span>
          </Menu.Item>
          {items?.status === "I" && (
            <Menu.Item
              key={`02`}
              onClick={() => {
                handleStatusChnage("A", items._id);
              }}
            >
              <LikeOutlined /> <span className="show_span">Active</span>
            </Menu.Item>
          )}
          {items?.status === "A" && (
            <Menu.Item
              key={`02`}
              onClick={() => {
                handleStatusChnage("I", items._id);
              }}
            >
              <DislikeOutlined /> <span className="show_span">Inactive</span>
            </Menu.Item>
          )}
          <Menu.Item
              key={`04`}
              onClick={() => {
                handleSendWelMail(items);
              }}
            >
              <FaRegEnvelope /> <span className="show_span">Send Welcome mail</span>
            </Menu.Item>
          <Menu.Item
              key={`05`}
              onClick={() => {
                handleBroadCastMail(items?.company_name);
              }}
            >
              <FaRegEnvelope /> <span className="show_span">Send Broadcast mail</span>
          </Menu.Item>
        </Menu>
      </>
    );
  };

  /*********************************************************
   *  This function is ued for handle filter input chnage
   *********************************************************/
  const handleFilterReset = () => {
    try {
      setFilter('');
      inputRef1.current.value = '';
      inputRef2.current.value = '';
      inputRef3.current.value = '';
      inputRef4.current.value = '';
    } catch (error) {
      
    }
  }

  const handleFilterApply = (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      setFilter((pre)=>({
        ...pre,
        'filter_by' : form.get('filter_by') || '',
        'search'    : form.get('search') || '',
        'to'        : form.get('to_date') || '',
        'from'      : form.get('from_date') || '',

      }))
      // console.log('filter',filter);
      // alert('submit');
      handleFiterDrawer();
      setSkip(0);
    } catch (error) {
      
    }
  }

  /*********************************************************
   *  This function will load when page load and with dependency update
   *********************************************************/
  useEffect(() => {
    getList();
    targetRef.current.scrollIntoView({
      behavior:'smooth',
    });
    document.title = "Chiratae Super Admin || Companies List";
  }, [currentPage, showRequest, popUpOpen, filter,sortByname,sortByDate]);

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <TobNavBar title={"Companies List"}></TobNavBar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                      onClick={() => setShowRequest("")}
                    />
                    <Tab
                      label="Active"
                      className="tabs_main_contnet"
                      {...a11yProps(1)}
                      onClick={() => setShowRequest("A")}
                    />
                    <Tab
                      label="Inactive"
                      className="tabs_main_contnet"
                      {...a11yProps(2)}
                      onClick={() => setShowRequest("I")}
                    />
                  </Tabs>
                </div>

                <div className="fa_filter_rating_review filter_img_and_add_btn_main">
                  <div className="inventory_filter_div" onClick={handleFiterDrawer}>
                    <img
                      src={ASSETS_BASE_URL + "./images/Filter.svg"}
                      alt="filter"
                      className="user_iconses"
                    />
                    <Link
                      to={false}
                      className="filter_btn_invontry_rating_review"
                    >
                      Filters
                    </Link>
                  </div>
                  <div onClick={() => handleEdit()}>
                    <button type="button" class="btn btn-dark">
                      <FaPlusSquare className="Promotions_faPlus_Squares" />
                      <span className="request_new_categories">Add New</span>
                    </button>
                  </div>
                  <div onClick={()=> {
                    navigate("/company/bulk-upload");
                  }}>
                    <button type="button" class="btn btn-success">
                      <FaUpload className="Promotions_faPlus_Squares" />
                      <span className="request_new_categories">Import</span>
                    </button>
                  </div>
                  <div onClick={()=> {
                    handleBulkMail();
                    }}>
                    <button type="button" class="btn btn-danger">
                      <FaRegEnvelope className="Promotions_faPlus_Squares" />
                      <span className="request_new_categories">Send Welcome mail to all</span>
                    </button>
                  </div>
                </div>
              </div>
            </Box>
            {/* First Inventory */}
            <VendorList 
              //value={value} index={0}
              >
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    {/* <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold common_inventory_heading">
                      {emailCompleteStatus}
                      </h6>
                    </div> */}
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>                              
                              { totalcompanycountread ? (
                                <tr><th style={{color:"red", fontSize:"18px",fontWeight:"600"}} colSpan={2}>{`Total - ${totalcompanycountread}`}</th></tr>
                              ):null}
                              <tr>
                              <th style={{width:"8%"}}>Sr no.</th>
                              <th>Image</th>
                              <th>Name {sortByname === "Y"? (<FaSortAlphaDown style={{marginLeft:"10px", cursor:"pointer"}} onClick={()=> handleNameSort()}/>):(<FaSortAlphaUp style={{marginLeft:"10px", cursor:"pointer"}} onClick={()=> handleNameSort()}/>)}</th>
                              <th>Founder Name</th>
                              <th>CEO Name</th>
                              <th>Stage</th>
                              <th>Contact Info</th>
                              <th>Created On <FaArrowsAltV onClick={()=> handleDateSort()} style={{marginLeft:"10px", cursor:"pointer"}}/></th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr key={index}>
                                    <td>{(currentPage - 1) * 10 + index + 1}</td>
                                    <td>
                                      {items?.company_image ? (
                                        <img
                                          src={`${items.company_image}`}
                                          alt={items?.company_image}
                                          width="50px"
                                        />
                                      ) : (
                                        <img
                                          src={`../images/IntentoryTableLogo.png`}
                                          alt={`profile-pic`}
                                          width="30px"
                                        />
                                      )}
                                    </td>
                                    <td>
                                      <div className="admin_user_list_table">
                                        <div className="admin_user_list_name_gmail">
                                          <span className="admin_user_list_name">
                                            {items?.company_name}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="admin_user_list_date">
                                      {" "}
                                      {items?.company_founder_name}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {" "}
                                      {items?.company_ceo_name}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {" "}
                                      {items?.company_stage}{" "}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {" "}
                                      {items?.company_phone}{" "}
                                      <br/>
                                      {items?.company_email}
                                    </td>
                                    <td className="admin_user_list_date">
                                      {moment(`${items?.createdAt}`).format(
                                        "DD/MM/YYYY, h:m A"
                                      )}{" "}
                                    </td>
                                    <td
                                      className="admin_user_list_date"
                                      dangerouslySetInnerHTML={{
                                        __html: statusMessage(items.status),
                                      }}
                                    ></td>
                                    <td>
                                      <Dropdown
                                        overlay={() => dropdownMenu(items)}
                                        placement="bottomLeft"
                                        arrow
                                      >
                                        <HiOutlineDotsHorizontal style={{cursor:"pointer"}}/>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : isLoading ? <SkeltonList row={10} col={10} /> : <tr> 
                                <td colspan="5" className="img-center" ><p>No data to show</p></td> 
                            </tr>}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination
                            count={TOTALPAGES}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </VendorList>
          </Box>
        </div>
        <Flex align="center" gap="middle">
          <Spin 
              size="large" 
              spinning={(emailCompleteStatus > 0 && emailCompleteStatus < 100)?true:false}
              fullscreen={true}
              tip={`${emailCompleteStatus} % completed`}
          >  </Spin>
        </Flex>

        <VendorListDeletePopUp
          popUpOpen={popUpOpen}
          togglePopUp={togglePopUp}
          deleteId={deleteId}
          setDeleteId={setDeleteId}
        />
      </div>
      {/* Filter */}
      <Drawer
        title="Company Filter"
        placement={`right`}
        width={500}
        onClose={handleFiterDrawer}
        open={isFilterShow}
        extra={
          <Space>
            <Button onClick={handleFiterDrawer}>Cancel</Button>
            <Button type="primary" onClick={handleFilterReset}>
              Reset
            </Button>
          </Space>
        }
      >
        <div className="responsive">
          <div className="row">
            <form id='filter_form' onSubmit={handleFilterApply}>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="investor_first_name" className="all_section_for_label_main_class" >
                  Search Field :
                </label>
                <select name="filter_by" className="form-control store_input_field" id="filter_by" ref={inputRef1}>
                  <option value=''>Select search field</option>
                  <option value='company_name' selected={filter?.filter_by ==='company_name'?true:false}>Name</option>
                  <option value='company_founder_name' selected={filter?.filter_by ==='company_founder_name'?true:false}>Founder name</option>
                  <option value='company_ceo_name' selected={filter?.filter_by ==='company_ceo_name'?true:false}>CEO name</option>
                  <option value='company_email' selected={filter?.filter_by ==='company_email'?true:false}>Email</option>
                  <option value='company_phone' selected={filter?.filter_by ==='company_phone'?true:false}>Phone</option>
                  <option value='status' selected={filter?.filter_by ==='status'?true:false}>Status</option>
                </select>
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="investor_first_name" className="all_section_for_label_main_class" >
                  Search Text :
                </label>
                <input
                  title="Enter search text."
                  placeholder="Enter search text."
                  type="text"
                  name="search"
                  className="form-control store_input_field"
                  id="search"  
                  ref={inputRef2}
                  // onChange={handleFilterChnage} 
                  // value={filter?.search}             
                />
              </div>
              {/* <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="investor_first_name" className="all_section_for_label_main_class" >
                  From :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="from_date"
                  className="form-control store_input_field"
                  id="from_date"      
                  ref={inputRef3}       
                />
              </div> */}
              {/* <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="investor_first_name" className="all_section_for_label_main_class" >
                  To :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="to_date"
                  className="form-control store_input_field"
                  id="to_date"   
                  ref={inputRef4}          
                />
              </div> */}
              <br/>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  title="Enter search text."
                  type="submit"
                  className="form-control btn btn-black"    
                  style={{ backgroundColor: "black", color: "white" }}           
                >Apply</button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </>
  );
}
