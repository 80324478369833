import {API_ADMIN_URL} from '../../config/constants'
import { postRequest } from '../API'
import axios from "axios";

/*********************************************************
* Function Name : List
* Description   : Get list of all service category
* By            : Afsar Ali
* Date          : 29-01-2024 
*********************************************************/
export const list = async (options) =>{
    try {
        const params = {
            url : `${API_ADMIN_URL}meetings/list`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function


/*********************************************************
* Function Name : addeditdata
* Description   : chnage service status
* By            : Afsar Ali
* Date          : 04-03-2024 
*********************************************************/
export const addeditdata = async (options) =>{
    try {
        const { url, postData={} } = options;
        const params = {
            url : `${API_ADMIN_URL}${url}`,
            postData : postData
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function


/*********************************************************
* Function Name : create
* Description   : chnage service status
* By            : Afsar Ali
* Date          : 04-03-2024 
*********************************************************/
export const create = async (options) =>{
    try {
        const { url, postData={} } = options;
        const params = {
            url : `${API_ADMIN_URL}${url}`,
            postData : postData
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function


/*********************************************************
* Function Name : chnageStatus
* Description   : chnage service status
* By            : Afsar Ali
* Date          : 04-03-2024 
*********************************************************/
export const chnageStatus = async (options) =>{
    try {
        const { url, postData={} } = options;
        const params = {
            url : `${API_ADMIN_URL}${url}`,
            postData : {
                ...postData,
                ipAddress : sessionStorage.getItem('IP_ADDRESS')
            }
        }
        const res = await postRequest(params);
        console.log('staus res',res);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function 



/*********************************************************
* Function Name : getCurrentMeetingSheet
* Description   : used to send welcome mail to all users
* By            : Sumit Bedwal
* Date          : 04-03-2024 
*********************************************************/
export const getCurrentMeetingSheet = async () =>{
    // console.log('options--',options);
    try{
        const url = 'https://chiratae.algoapi.in/apiv2/exceldownload/updatedfromadmin';
        const headers = { 'Authorization' : 'KztpEM93Xq25ubxhFLHdsT4raVYJPBckygAn68U7' };
        return await axios.post(url, {}, { headers });
        // const reees = await axios.post(url, {}, { headers });
    } catch(error){
        return error;
    }
};//End of Function
