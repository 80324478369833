module.exports = {
    API_KEY : "4a1fc76a90fd7469e2d84d145e3b1eab",

    // API_ADMIN_URL : "https://api.tryfrisbee.com/admin/",
    // API_BASE_URL : "https://api.tryfrisbee.com/",

    // API_ADMIN_URL : "https://algodev.in:60000/admin/",
    // API_BASE_URL : "https://algodev.in:60000/",

    API_ADMIN_URL : "https://api.spotlight.chiratae.com/admin/",
    API_BASE_URL : "https://api.spotlight.chiratae.com/",
    
    // API_ADMIN_URL : "http://localhost:3301/admin/",
    // API_BASE_URL : "http://localhost:3301/",

    // INVESTOR_LOGIN_URL : "https://algodev.in:61000/investor-login",
    // COMPANY_LOGIN_URL : "https://algodev.in:61000/company-login",
    INVESTOR_LOGIN_URL : "https://spotlight.chiratae.com",
    COMPANY_LOGIN_URL : "https://spotlight.chiratae.com",
    
    ASSETS_BASE_URL : "https://frisbee-llc.s3.me-central-1.amazonaws.com/",
    GOOGLE_MAP_API : 'AIzaSyDeiXX90FyborPREpX0JQZ9G-j0Ooy9-YM'
}