import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";

import { FaDesktop, FaArrowLeft } from "react-icons/fa";

import "react-quill/dist/quill.snow.css";
import ExcelJS from 'exceljs';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { notification, Progress, Upload, Modal, Button } from "antd";
import { CheckCircleTwoTone, CloseOutlined, InfoCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { insertBulkInterest } from "../../controllers/interests/requestsController";
const conicColors = {
  '0%': '#87d068',
  '50%': '#ffe58f',
  '100%': '#ffccc7',
};
const ImportPopUp = ({ popUpOpen, togglePopUp }) => {
  const [fileName, setFileName] = useState('');
  const { Dragger } = Upload;
  const [error, setError] = useState([]);
  const [progress, setProgress] = useState(0);
  const [jsonData, setJsonData] = useState(null);


  const handleCancel = () => {
    togglePopUp();
    setFileName('');
    setFileName('');
    setProgress(0);
    setJsonData(null);
  };

  /*********************************************************
   *  This function is use to handle success notification show
   *********************************************************/
  const handleSuccessMsg = () => {
    notification.open({
      message: "Success!",
      description: " Data Saved Successfully .",
      placement: "topRight",
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
      duration : 2
    });
  }; //End

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bulkErrorMsg, setBulkerrorMsg] = useState("");
  const showModal = (msg) => {
    setBulkerrorMsg(msg);
    setIsModalOpen(true);
  };

  /*********************************************************
   *  This function is use to handle image object
   *********************************************************/
  const handleFileChange = (e) => {
    // setImageList(e.fileList[0].originFileObj);
    setFileName(e.fileList[0]?.originFileObj?.name);
    const file = e.fileList[0].originFileObj;
    const workbook = new ExcelJS.Workbook();
    const reader = new FileReader();

    reader.onload = async (event) => {
      const buffer = event.target.result;
      await workbook.xlsx.load(buffer);

      const worksheet = workbook.worksheets[0];
      const rows = [];

      worksheet.eachRow((row, rowNumber) => {
        const rowValues = row.values.slice(1); // Remove the first empty value
        rows.push(rowValues);
      });

      // Convert rows to JSON (assuming first row is the header)
      const headers = rows[0];
      const data = rows.slice(1).map(row => {
        const rowData = {};
        row.forEach((cell, index) => {
          rowData[headers[index]] = cell;
        });
        // console.log('rowData',rowData);
        return rowData;
      });
      setJsonData(data);
    };
    reader.readAsArrayBuffer(file);
    setError((pre)=>({
      ...pre,
      file : ""
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
          const option = {
              data: jsonData
          };
          // console.log('myJson data',option);
          if (jsonData?.length > 0) {
              try {
                  const dataArray = await transformData(option);
                  let bulkError = false;
                  if (dataArray && dataArray?.length > 0) {
                      for (let i = 0; i < dataArray.length; i += 10) {
                          const batch = dataArray.slice(i, i + 10);
                          const res = await insertBulkInterest(batch);
                          if (res.status !== true) {
                              bulkError = true;
                                // showModal(res?.message);
                                notification.open({
                                  message: "Opps!",
                                  description: `${res?.message}`,
                                  placement: "topRight",
                                  icon: <CheckCircleTwoTone twoToneColor="#ff0000" />,
                                  duration : 4
                                });
                                setProgress(0);
                                handleCancel();
                                break;
                          }
                          const progressPercentage = Math.round(((i + 10) / dataArray.length) * 100);
                          setProgress(progressPercentage);
                      }
                      if(bulkError === false){
                        handleSuccessMsg();
                        handleCancel();
                      }
                  }
              } catch (error) {
                  setError((pre) => ({
                      ...pre,
                      formError: error.message,
                  }));
              }
          } else {
              setError((pre) => ({
                  ...pre,
                  file: "Please upload file"
              }));
          }
    //   } 
    } catch (error) {
      console.log('error',error)
    }
};

  const transformData = async (option) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { data } = option;
            let result = [];

            for (let i = 0; i < data.length; i += 10) {
                const batch = data.slice(i, i + 10);
                const transformedBatch = await Promise.all(batch.map(async (item) => {
                  let investorEmail = '';
                  if (typeof item['Investor Email'] === 'string') {
                    investorEmail = item['Investor Email']
                  } else {
                    investorEmail = item['Investor Email']?.text
                  }

                  let companyEmail = '';
                  if (typeof item['Company Email'] === 'string') {
                    companyEmail = item['Company Email']
                  } else {
                    companyEmail = item['Company Email']?.text
                  }

                  return {
                    serial_no: item?.serial_no,
                    company_email: companyEmail,
                    investor_email: investorEmail,
                    investor_priority : item['Priority'],
                    status: item['Status']
                  };
                }));
                result = result.concat(transformedBatch);
            }
            resolve(result);
        } catch (error) {
            reject(error);
        }
    });
};

  return (
    <Dialog
      open={popUpOpen}
      onClose={togglePopUp}
      maxWidth="md"
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        <div className="myMainDrawerClass">
          <div>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              
            </Typography>
          </div>
          <div>
            <IconButton onClick={togglePopUp}>
              {/* <IoMdClose /> */}
            </IconButton>
          </div>
        </div>
        <Typography
          variant="h4"
          component="div"
          className="my_delete_popup_paragraph"
          style={{textAlign:'center'}}
        >
        </Typography>
          <div className="responsive">
              <form action="" >
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1" className="all_vendor_radio_button_div">File (<Link to={`/companyTemplate/Interest.xlsx`} target="_blank">Download Template)</Link></label>
                  <Dragger
                    className="about_us_dragger_file"
                    onChange={handleFileChange}
                    accept='.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    showUploadList={false}
                  >
                    <button type="button" className="about_us_dragger_file_button" > {fileName?fileName:`Upload file`}</button> 
                    <p style={{color:"blue"}}>*Note: Please choose a file of xlsx format only.</p>
                    {error?.file ? ( <p style={{ color: "red" }}>{error.file}</p> ) : ("")}
                  </Dragger>
                </div>
                {progress === 0 ? (
                  <div className="inventory_edit_submit_btn">
                    <button type="button" className="btn btn-primary inventory_save_button all_save_btn_btn_btn"
                    onClick={handleSubmit} 
                    > Upload </button>
                  </div>
                ):(
                  <div class="form-group">
                    <div class="form-group">
                      <Progress type="line" percent={progress} strokeColor={conicColors} />
                    </div>
                  </div>
                )}
              </form>
            </div>
      </div>
    </Dialog>
  );
};

export default ImportPopUp;
