import React, { useState } from "react";
import { IoSettingsOutline, IoNotificationsOffSharp } from "react-icons/io5";
import { MdOutlinePayment } from "react-icons/md";
import { IoMdStarOutline } from "react-icons/io";
import { BiSupport } from "react-icons/bi";
import { CiDiscount1 } from "react-icons/ci";
import { NavLink } from "react-router-dom";
import { TbCategory } from "react-icons/tb";
import { MdOutlineInventory2 } from "react-icons/md";
import { IoHomeOutline } from "react-icons/io5";
import { RiBarChartHorizontalLine } from "react-icons/ri";
import { CiMail } from "react-icons/ci";
import LogoImg from "../../Image/logo.png";
import { CiShoppingBasket } from "react-icons/ci";
import { FaGreaterThan } from "react-icons/fa";
import { VscAccount, VscFeedback , VscGraph } from "react-icons/vsc";
import { TbPointFilled, TbUserCheck, TbUsers } from "react-icons/tb";
import { Collapse } from "antd";
import "./SideBar.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";

import {
  getRandomColor,
  createImageFromInitials,
} from "../../controllers/common";
import { ASSETS_BASE_URL } from "../../config/constants";

const { Panel } = Collapse;

const SidebarItem = ({ to, icon, name, isOpen, className }) => (
  <NavLink to={to} className={`link ${className}`} activeClassName="active">
    <div className="icon">{icon}</div>
    <div style={{ display: isOpen ? "block" : "none" }} className="link_text">
      {name}
    </div>
  </NavLink>
);

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleCollapseToggle = () => {
    setIsOpen(!isOpen);
  };
  const user = JSON.parse(sessionStorage.getItem("ADMIN-INFO"));
  const [USERDATA, setUserData] = useState(user || "");
  return (
    <div
      className={`container-fluid side_bar_main_div ${
        isOpen ? "sidebar-open" : "sidebar-closed"
      }`}
    >
      <div
        style={{ width: isOpen ? "300px" : "70px", position: "relative " }}
        className="sidebar"
      >
        <div className="top_section">
          <img
            src={LogoImg}
            className="sidebar_logo"
            alt="Logo"
            style={{
              display: isOpen ? "block" : "none",
              height: "45px",
              width: "auto",
            }}
          />
          <div
            style={{ marginLeft: isOpen ? "0px" : "0px" }}
            className="bars"
          >
            <RiBarChartHorizontalLine onClick={handleCollapseToggle} />
          </div>
        </div>
        <div className="my_sidebar_all_section">
          <SidebarItem
            to="/dashboard"
            icon={<IoHomeOutline />}
            name="Dashboard"
            isOpen={isOpen}
          />
          {/* //For Account Collapse */}
          <div>
            <Collapse accordion className="my_collapse_icon">
              <Panel
                header={
                  <div>
                    <VscAccount className="sidebar_collapse_iohomeoutline" />
                    <span
                      className={`sidebar_collapse_iohomeoutline_categoires ${
                        isOpen ? "visible" : "hidden"
                      }`}
                    >
                      Accounts
                    </span>
                  </div>
                }
                key="categories"
                className={`side_bar_categories ${
                  isOpen ? "arrow-visible" : "arrow-hidden"
                }`}
              >
                <SidebarItem
                  to="/company/list"
                  icon={<TbPointFilled />}
                  name="Companies"
                  isOpen={isOpen}
                ></SidebarItem>
                <SidebarItem
                  to="/investors/list"
                  icon={<TbPointFilled />}
                  name="Investors"
                  isOpen={isOpen}
                ></SidebarItem>
              </Panel>
            </Collapse>
          </div>
          
          {/* <div>
            <Collapse accordion className="my_collapse_icon">
              <Panel
                header={
                  <div>
                    <TbCategory className="sidebar_collapse_iohomeoutline" />
                    <span
                      className={`sidebar_collapse_iohomeoutline_categoires ${
                        isOpen ? "visible" : "hidden"
                      }`}
                    >
                      CMS
                    </span>
                  </div>
                }
                key="categories"
                className={`side_bar_categories ${
                  isOpen ? "arrow-visible" : "arrow-hidden"
                }`}
              >
                <SidebarItem to="/cms/reasons" icon={<TbPointFilled />} name="Reasons" isOpen={isOpen} ></SidebarItem>              
            </Panel>
            </Collapse>
          </div> */}

          <div>
            <Collapse accordion className="my_collapse_icon">
              <Panel
                header={
                  <div>
                    <TbCategory className="sidebar_collapse_iohomeoutline" />
                    <span className={`sidebar_collapse_iohomeoutline_categoires ${isOpen ? "visible" : "hidden"}`} >
                      Events Manage
                    </span>
                  </div>
                }
                key="categories"
                className={`side_bar_categories ${isOpen ? "arrow-visible" : "arrow-hidden"}`}
              >
                <SidebarItem to="/events/list" icon={<TbPointFilled />} name="Events" isOpen={isOpen} ></SidebarItem>
                <SidebarItem to="/events/slots-list" icon={<TbPointFilled />} name="Slots List" isOpen={isOpen} ></SidebarItem>
                <SidebarItem to="/events/avg-settings-list" icon={<TbPointFilled />} name="Settings List" isOpen={isOpen} ></SidebarItem>
              </Panel>
            </Collapse>
          </div>

          <div>
                <SidebarItem to="/requests-sent/list" icon={<TbUserCheck/>} name="Interests" isOpen={isOpen} ></SidebarItem>
          </div>

          <div>
                <SidebarItem to="/meetings/list" icon={<TbUsers/>} name="Meetings" isOpen={isOpen} ></SidebarItem>
          </div>

          <div>
            <Collapse accordion className="my_collapse_icon">
              <Panel
                header={
                  <div>
                    <VscGraph className="sidebar_collapse_iohomeoutline" />
                    <span className={`sidebar_collapse_iohomeoutline_categoires ${isOpen ? "visible" : "hidden"}`} >
                      Analytics
                    </span>
                  </div>
                }
                key="categories"
                className={`side_bar_categories ${isOpen ? "arrow-visible" : "arrow-hidden"}`}
              >
                <SidebarItem to="/analytics/cumulative-reports" icon={<TbPointFilled />} name="Cumulative Reports" isOpen={isOpen} ></SidebarItem>
              </Panel>
            </Collapse>
          </div>

          <div>
                <SidebarItem to="/feedbacks/list" icon={<VscFeedback />} name="Feedbacks" isOpen={isOpen} ></SidebarItem>
          </div>

          <div>
            <SidebarItem
              icon={
                <img 
                  src={
                    user?.admin_image
                      ? LogoImg
                      : LogoImg
                  }
                  alt=""
                  className="user_profile_pic_sidebar"
                  onClick={handleCollapseToggle}
                  width="100px"
                />
              }
              to="/profile"
              name={
                <div className="sidebar_profile_main_content_section">
                  <div>
                    <div className="sidebar_profile_main_content">
                      <div className="user_profile_pic_Admin_name">
                        <span className="user_profile_pic_Admin_panel">
                          {`${user?.admin_first_name} ${user?.admin_last_name}`}
                        </span>{" "}
                        <br />
                        <span className="user_profile_pic_Admin_panel_">
                          Admin
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <FaGreaterThan
                      className="side_bar_fagreaterthan"
                      onClick={handleCollapseToggle}
                    />
                  </div>
                </div>
              }
              isOpen={isOpen}
              className="custom_profile_class_productList"
            >
              {/* Your collapse content goes here */}
              {/* {isOpen && (
                <div className="collapse-content">Your Collapse Content</div>
              )} */}
            </SidebarItem>
          </div>
        </div>
      </div>
      <main>{children}</main>
    </div>
  );
};

export default Sidebar;
