import React, { useEffect } from "react";
import "./App.css";


import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { fetchIpAddress } from "./controllers/API"

import LoginPage from "./components/LoginPage/LoginPage.js";
import ResetPassword from "./components/LoginPage/ResetPassword.js";

import Sidebar from "./components/SideBar/Sidebar.jsx";
import Dashboard from "./pages/DashBaord/Dashboard.jsx";

// import UserList from "./pages/Accounts/UserList/UserList.js";
// import UserListEditPage from "./pages/Accounts/UserList/UserListEditPage.js";

import CompanyList from "./pages/Accounts/CompanyList/CompaniesList.js";
import CompanyListEditPage from "./pages/Accounts/CompanyList/CompanyAddEditData.js";
import CompanyBulkUpload from "./pages/Accounts/CompanyList/companyBulkUpload.js";
import InvestorsList from "./pages/Accounts/InvestorList/InvestorsList.js";
import InvestorBulkUpload from "./pages/Accounts/InvestorList/investorBulkUpload.js";
import InvestorListEditPage from "./pages/Accounts/InvestorList/InvestorAddEditData.js";

import EventsLists from "./pages/Events/eventsList.js";
import EventsListEditPage from "./pages/Events/addeditEvents.js";

import SlotsList from "./pages/Events/slotsLists.js";
import SlotsEditPage from "./pages/Events/addeditSlots.js";

import AvgSettingList from "./pages/Events/avgSettingsList.js";
import AvgSettingEditPage from "./pages/Events/addEditAvgSettings.js";

import RequestSentLists from "./pages/Requests/requestSentList.js";
import ScheduleMeetings from "./pages/Requests/ScheduleMeetings.js";

import MeetingsList from "./pages/meetings/meetingsList.js";
import EditMeetings from "./pages/meetings/addeditMeetings.js";
import CreateMeeting from "./pages/meetings/createMeetings.js";

import Reasons from "./pages/CMS/Reasons/reason.js";

import Feedbacks from "./pages/Feedbacks/feedBacksList.js";

// import Insights from "./pages/Insights/List.js";
// import Insurance from "./pages/Insurance/List.js";
// import InsuranceDetails from "./pages/Insurance/InsuranceDetails.js";
// import Inquery from "./pages/Inquiry/List.js";

import Profile from "./pages/BottomProfile/Profile.js";


import RatingAndReview from "./pages/Rating And Review/List.js"; ///// table css file

import StoreDetailsForm from "./pages/BottomProfile/StoreDetailsForm.js";
import Setiting from "./pages/SettingPage/Setiting.js";

import AdminDashboardEditPage from "./pages/DashBaord/DashboardEditPage.js";


import CumulativeReports from "./pages/analytics/cumulativereports.js";


const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  

  const getIp = async () => {
    await fetchIpAddress();
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      await getIp();
      const token = sessionStorage.getItem("TOKEN");

      if (!["/", "/login", "/reset-password"].includes(location?.pathname)) {
        if (!token) {
          navigate("/");
        } else {
          
        }
      }
    };

    checkAuthentication();
  }, [location, navigate]);

  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/dashboard" element={ <Sidebar> <Dashboard /> </Sidebar> } />

          {/* <Route path="/users/list" element={ <Sidebar> <UserList /> </Sidebar> } />
          <Route path="/users/addeditdata" element={ <Sidebar> <UserListEditPage /> </Sidebar> } /> */}

          <Route path="/investors/list" element={ <Sidebar> <InvestorsList /> </Sidebar> } />
          <Route path="/investors/addeditdata" element={ <Sidebar> <InvestorListEditPage /> </Sidebar> } />
          <Route path="/investors/bulk-upload" element={<Sidebar><InvestorBulkUpload/></Sidebar>} />

          <Route path="/company/list" element={ <Sidebar> <CompanyList /> </Sidebar> } />
          <Route path="/company/addeditdata" element={ <Sidebar> <CompanyListEditPage /> </Sidebar> } />
          <Route path="/company/bulk-upload" element={ <Sidebar><CompanyBulkUpload/></Sidebar>}/>

          <Route path="/events/list" element={ <Sidebar> <EventsLists /> </Sidebar> } />
          <Route path="/events/addeditdata" element={ <Sidebar> <EventsListEditPage /> </Sidebar> } />

          <Route path="/events/slots-list" element={ <Sidebar><SlotsList/></Sidebar>} />
          <Route path="/events/addeditslots" element={ <Sidebar><SlotsEditPage/></Sidebar>} />
          
          <Route path="/events/avg-settings-list" element={<Sidebar><AvgSettingList/></Sidebar>} />
          <Route path="/events/addedit-avg-settings" element={<Sidebar><AvgSettingEditPage/></Sidebar>} />

          <Route path="/requests-sent/list" element={<Sidebar><RequestSentLists/></Sidebar>} />
          <Route path="/requests-sent/schedule-meeting" element={<Sidebar><ScheduleMeetings/></Sidebar>} />

          <Route path="/meetings/list" element={<Sidebar><MeetingsList/></Sidebar>} />
          <Route path="/meetings/addeditdata" element={<Sidebar><EditMeetings/></Sidebar>} />
          <Route path="/meetings/create" element={<Sidebar><CreateMeeting/></Sidebar>} />

          <Route path="/feedbacks/list" element={<Sidebar><Feedbacks/></Sidebar>} />
                    
          {/* CMS */}
	        <Route path="/cms/reasons" element={<Sidebar> <Reasons/> </Sidebar>} />

          <Route path="/analytics/cumulative-reports" element={<Sidebar><CumulativeReports/></Sidebar>} />


          <Route
            path="/profile"
            element={
              <Sidebar>
                <Profile />
              </Sidebar>
            }
          />
          
          <Route path="/rating-review" element={ <Sidebar> <RatingAndReview /> </Sidebar> }  />

          <Route
            path="/storeDetailsForm"
            element={
              <Sidebar>
                <StoreDetailsForm />
              </Sidebar>
            }
          />
          <Route
            path="/setiting"
            element={
              <Sidebar>
                <Setiting />
              </Sidebar>
            }
          />
          
          
          <Route
            path="/adminDashboardEditPage"
            element={
              <Sidebar>
                <AdminDashboardEditPage />
              </Sidebar>
            }
          />
        </Routes>
      
    </div>
  );
};

export default App;
